import React from 'react';

import colors from '../colors';

const ship = {
  path: (
    <>
      <path d="M1184.6 603H0v166.4l592.3 166.4 592.3-166.4V603z" fill={colors.shipBlue[500]} />

      <path
        d="M307.5 0h262.1v262.1H307.5V0zM615 0h262.1v262.1H615zM307.5 300.9h262.1V563H307.5zM615 300.9h262.1V563H615z"
        fill={colors.shipRed[500]}
      />

      <path fill={colors.shipBlue[500]} d="M922.5 300.9h262V633h-262zM0 300.9h262V633H0z" />
    </>
  ),
  viewBox: '0 0 1185 936',
};

export default ship;
