import React from 'react';
import { Box } from '@chakra-ui/core';
import Card from '../../../../components/Card';
import { useFormikContext } from 'formik';

import FormTextArea from '../../../../components/FormTextArea';

import { FormData } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

// Types
type Props = {};

const InputFormNormal = (_props: Props) => {
  const { handleBlur, handleChange, values } = useFormikContext<FormData>();
  const { t } = useTranslation('translation');

  return (
    <Card cardTitle={t('deidentOptions.additional.title')}>
      <Box as="section">
        <FormTextArea
          id="kommentar"
          isRequired={false}
          isValid={true}
          label={t('deidentOptions.additional.comment')}
          labelColor="formLabel"
          name="kommentar"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={t('deidentOptions.additional.comment')}
          type="text"
          value={values.kommentar}
          helperText="(Optional)"
        />
      </Box>
    </Card>
  );
};

export default InputFormNormal;
