import React from 'react';

import CurrentStatus from '../../components/CurrentStatus';

type Props = {
  shouldDeidentify: boolean;
  status: string;
};

const CurrentStatusContainer = ({ shouldDeidentify, status }: Props) => {
  if (status === 'running' || status === 'done' || status === 'uploading') {
    return <CurrentStatus status={status} shouldDeidentify={shouldDeidentify} />;
  }

  return null;
};

export default CurrentStatusContainer;
