import React, { useEffect, useCallback, forwardRef } from 'react';
import { Flex } from '@chakra-ui/core';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { buildDeidentPayload, buildNormalPayload } from './utils';
import { checkFileTypes } from '../../utils/file/fileCheckUpload';
import { fileUpload } from '../../utils/orthanc/fileUpload';
import { xmlHttpRequestAnalyzer } from '../../utils/errorHandling/xmlHttpRequestAnalyzer';
import Button from '../../components/Button';
import DropZone from '../DropZone';
import DropzoneFileCounter from '../../components/DropzoneFileCounter';
import CurrentStatusContainer from '../CurrentStatusContainer';
import Progress from '../ProgressContainer';
import UploadBox from '../../components/UploadFlex';
import UploadStatusContainer from '../UploadStatus';
import { useAsyncError } from '../../utils/errorHandling/useAsyncError';

import { DeidentUserInputOptions, ErrorInfo, FormData, NotAcceptedFile } from '../../types/types';
import { FileWithPath } from 'react-dropzone';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  isValid: boolean;
  shouldDeidentify: boolean;
  userFormInput: FormData;
};

// const Child = forwardRef((props, ref) => {
const FileUploadContainer = forwardRef(({ deidentUserInputOptions, isValid, shouldDeidentify, userFormInput }: Props, ref) => {
  FileUploadContainer.displayName = 'FileUploadContainer';

  const [allowUpload, setAllowUpload] = React.useState(false);
  const [isFileCheckInProgress, setIsFileCheckinProgress] = React.useState<string>('idle');
  const [checkedFiles, setCheckedFiles] = React.useState<FileWithPath[]>([]);
  const [deidentifyedFiles, setDeidentifyedFiles] = React.useState([] as File[]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [notAcceptedFiles, setNotAcceptedFiles] = React.useState<NotAcceptedFile[]>([]);
  const [shouldUpload, setShouldUpload] = React.useState(false);
  const [uploadErrors, setUploadErrors] = React.useState<undefined | ErrorInfo[]>();
  const [uploadErrorText, setUploadErrorText] = React.useState<string | undefined>();
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const currentShouldDeidentify = React.useRef<boolean>();
  const [uploadIsDone, setUploadIsDone] = React.useState<boolean>(false);
  const { t } = useTranslation('translation');
  const studyUIDList = React.useRef([] as string[]);

  // Funktion zum Hinzufügen eines neuen Strings, wenn er nicht bereits im Array enthalten ist
  const addUniqueString = (newString: string) => {
    if (!studyUIDList.current.includes(newString)) {
      studyUIDList.current = [...studyUIDList.current, newString];
    }
  };

  const rejectedFiles = notAcceptedFiles.filter(
    file => file.reason === 'Secondary Captures Image Storages' || file.reason === 'burned-in annotations',
  );

  const throwAsyncError = useAsyncError();

  const onDrop: (acceptedFiles: File[]) => void = useCallback(acceptedFiles => {
    // Reset before upload
    setUploadErrors(undefined);
    setUploadProgress(0);
    setIsFileCheckinProgress('running');
    checkFileTypes(
      addUniqueString,
      acceptedFiles,
      setNotAcceptedFiles,
      setCheckedFiles,
      setAllowUpload,
      setIsFileCheckinProgress,
      currentShouldDeidentify,
    );
  }, []);

  React.useImperativeHandle(ref, () => ({
    fileCheck() {
      checkFileTypes(
        addUniqueString,
        acceptedFiles,
        setNotAcceptedFiles,
        setCheckedFiles,
        setAllowUpload,
        setIsFileCheckinProgress,
        currentShouldDeidentify,
      );
    },
  }));

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    disabled: isUploading,
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDrop,
  });

  const throwError = (e: any) => {
    throwAsyncError(new Error(e));
  };

  const startUpload = async () => {
    if (isValid) {
      // Reset
      setIsFileCheckinProgress('uploading');
      setDeidentifyedFiles([]);
      setAllowUpload(false);

      // Upload normal dicom files without deidentified tags
      if (!shouldDeidentify) {
        if (checkedFiles.length > 0) {
          buildNormalPayload(studyUIDList, checkedFiles, setDeidentifyedFiles, setShouldUpload, userFormInput);
          setIsUploading(true);
        }
      }

      // Upload dicom files with deidentified tags
      if (shouldDeidentify) {
        if (checkedFiles.length > 0) {
          const deidentPromise = Promise.resolve(
            buildDeidentPayload(
              studyUIDList,
              checkedFiles,
              deidentUserInputOptions,
              setDeidentifyedFiles,
              setShouldUpload,
              userFormInput,
              setAllowUpload,
            ),
          );
          await deidentPromise
            .then(async () => {
              setIsUploading(true);
            })
            .catch(error => {
              console.info('Just for debugging');
              console.log(error);
              console.log(error.cause);
              throwError(error);
            });
        }
      }
    }
  };

  useEffect(() => {
    currentShouldDeidentify.current = shouldDeidentify;
  }, [shouldDeidentify]);

  useEffect(() => {
    if (shouldUpload === true) {
      fileUpload(deidentifyedFiles, setUploadProgress)
        .then(response => {
          setUploadErrors(xmlHttpRequestAnalyzer(response));
          setShouldUpload(false);
          setIsUploading(false);
          setAllowUpload(false);
          setIsFileCheckinProgress('idle');
          setUploadIsDone(true);
        })
        .catch(error => {
          setUploadErrorText(error.toString());
          setIsUploading(false);
        });
    }
  }, [checkedFiles, deidentifyedFiles, shouldUpload]);

  useEffect(() => {
    // Show not accepted files in console
    if (isFileCheckInProgress === 'done' && notAcceptedFiles.length > 0) {
      // eslint-disable no-console, no-control-regex
      console.log('%c Rejected files:', 'color: #0000ff; background: #f4f4ee');
      // eslint-disable no-console, no-control-regex
      console.log(
        '%c  Often there are additional files between important Dicom files. These usually do not contain any data relevant for the study and cannot be processed by the server. Therefore, these files are not transferred. (E.g. files with the extension .dir, .xml)',
        'color: #000000; background: #f4f4ee',
      );
      // eslint-disable no-console, no-control-regex
      console.dir(notAcceptedFiles);
    }
    if (checkedFiles.length === 0) {
      setAllowUpload(false);
      setIsFileCheckinProgress('idle');
    }
  }, [acceptedFiles, checkedFiles, notAcceptedFiles, isFileCheckInProgress]);

  return (
    <>
      <Flex align="flex-start" direction="column" justify="center">
        <UploadBox>
          <DropZone
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            checkedFiles={checkedFiles}
            status={isFileCheckInProgress}
            shouldDeidentify={shouldDeidentify}
          />
        </UploadBox>
        <DropzoneFileCounter count={checkedFiles.length} />
      </Flex>

      <Progress uploadProgress={uploadProgress} />

      <CurrentStatusContainer status={isFileCheckInProgress} shouldDeidentify={shouldDeidentify} />

      <Flex mt={4}>
        <Button
          isLoading={isUploading}
          isDisabled={!allowUpload}
          loadingText={`${t('upload.uploadButton')}...`}
          ml="auto"
          size="md"
          type="submit"
          onClick={() => startUpload()}
        >
          {t('upload.uploadButton')}
        </Button>
      </Flex>

      <UploadStatusContainer
        uploadErrors={uploadErrors}
        uploadErrorText={uploadErrorText}
        rejectedFiles={rejectedFiles}
        uploadIsDone={uploadIsDone}
      />
    </>
  );
});

export default FileUploadContainer;
