import React from 'react';
import { Heading } from '@chakra-ui/core';

// Types
import { HeadingProps } from '@chakra-ui/core';
import { ReactNode } from 'react';

export type CardTitleProps = {
  cardTitle?: string | ReactNode;
  children?: ReactNode;
  isLoading?: boolean;
} & HeadingProps;

const CardTitle: React.FC<CardTitleProps> = React.forwardRef<any, CardTitleProps>(({ children, ...rest }: CardTitleProps, ref) => {
  return (
    <Heading as="h2" fontSize="lg" fontWeight="semibold" mb={3} ref={ref} {...rest}>
      {children}
    </Heading>
  );
});

CardTitle.displayName = 'CardTitle';

export default CardTitle;
