import { buildKeepTags } from './buildKeepTags';
import {
  DicomDeidentifier,
  Tag,
  RetainDeviceIdentOption,
  // RetainLongModifDatesOption,
  formatDate,
  parseDate,
  // RetainInstIdentOption,
  // RetainSafePrivateOption,
  // RetainPatientCharsOption,
  RetainLongFullDatesOption,
} from '@umessen/dicom-deidentifier';

import isNotNullOrUndefined from '../isNotNullOrUndefined';

import { DeidentUserInputOptions, FormData } from '../../types/types';
import { FileWithPath } from 'react-dropzone';
import { keepDateTime } from '../../config/config';

export const deidentifyTags = async (
  dicomFile: FileWithPath,
  deidentUserInputOptions: DeidentUserInputOptions,
  userFormInput: FormData,
) => {
  return new Promise<Uint8Array>((resolve, reject) => {
    const read = new FileReader();
    read.readAsArrayBuffer(dicomFile);
    read.onload = async () => {
      const readResult = read.result;

      try {
        // read.result could be null or string. I am not sure why or when this could happen.
        // But it will break the app. We have to investigate more in this behavior
        if (!(readResult instanceof ArrayBuffer)) {
          const readResultType = typeof readResult;
          throw Error('Wrong Type, read.result type is not ArrayBuffer', {
            cause: {
              type: readResultType,
              readResult: read.result,
              filePath: dicomFile.path,
              fileSize: dicomFile.size,
              fileName: dicomFile.name,
            },
          });
        }

        const byteArray = new Uint8Array(readResult);

        const retainProfileOptions = keepDateTime ? [RetainDeviceIdentOption] : [RetainDeviceIdentOption, RetainLongFullDatesOption];

        const deidentifier = new DicomDeidentifier({
          profileOptions: retainProfileOptions,
          getReferenceTime: dictionary => parseDate(dictionary['00080030'].Value[0] as string, 'TM'),
          getReferenceDate: () => new Date('1967-10-12'),
          dummies: {
            default: 'removed',
            lookup: {
              [Tag.forName('PatientName')]: userFormInput.fullPatientID ?? 'removed',
              [Tag.forName('PatientAge')]: 'removed',
              [Tag.forName('PatientID')]: userFormInput.fullPatientID ?? 'UPLOAD-NoStudyName-removed',
            },
          },
          specialHandlers: [
            (element, options) => {
              if (keepDateTime) {
                if (element.getVR() === 'DA' || element.getVR() === 'DT') {
                  const date = options.dateShiftFunction!(
                    options.referenceDate,
                    parseDate(element.getValue()[0] as string, element.getVR() as any),
                  );
                  element.setValue([formatDate(date, element.getVR() as any)]);
                  return true;
                } else if (element.getVR() === 'TM') {
                  const date = options.timeShiftFunction!(
                    options.referenceTime,
                    parseDate(element.getValue()[0] as string, element.getVR() as any),
                  );
                  element.setValue([formatDate(date, element.getVR() as any)]);
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            },

            // Fix for https://gitlab.ume.de/ship/app-dicom-uploader/-/issues/18
            element => {
              if (element.getTag() === '00083010' && element.getVR() === 'UN') {
                console.warn(
                  "Skipped (0008,3010) 'Irradiation Event UID' because its value representation is UNKNOWN (VR=UN). This will cause trouble otherwise because VR=UI is expected for this tag.",
                );
                return true;
              } else {
                return false;
              }
            },
          ],
          keep: buildKeepTags(deidentUserInputOptions),
        });
        resolve(deidentifier.deidentify(byteArray));
      } catch (ex: any) {
        // eslint-disable no-console, no-control-regex
        return reject(ex);
      }
    };
    // read.onerror = reject;
  }).then(res => {
    return res;
  });
};

const buildDicomFile = (dicomData: Uint8Array, fileName: string) => {
  if (isNotNullOrUndefined(dicomData)) {
    const blob = new Blob([dicomData], { type: 'application/dicom' });
    const file = new File([blob], fileName, { type: 'application/dicom' });

    // For Debugging only. Download the file
    // var saveData = (function () {
    //   var a = document.createElement('a');
    //   document.body.appendChild(a);
    //   return function () {
    //     const url = window.URL.createObjectURL(file);
    //     a.href = url;
    //     a.download = file.name;
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //   };
    // })();
    // saveData();
    return file;
  }
  return null;
};

export const deidentifyFile = async (
  dicomFile: FileWithPath,
  deidentUserInputOptions: DeidentUserInputOptions,
  userFormInput: FormData,
) => {
  const deidentedDataSet = await deidentifyTags(dicomFile, deidentUserInputOptions, userFormInput);
  return buildDicomFile(deidentedDataSet, dicomFile.name);
};
