import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';
import { statusCheck, responseMsgCheck } from './utils';

import { userInputFileName } from '../../files/fileName';

import { ErrorInfo, XMLHttpRequestWithFileName } from '../../types/types';

const err = [] as ErrorInfo[];

const checkResponse = (response: XMLHttpRequestWithFileName) => {
  if (statusCheck(response.xhr.status)) {
    const testUploadSuccess = responseMsgCheck(response.xhr);
    if (testUploadSuccess === 'AlreadyStored' && response.file.name !== userInputFileName) {
      const error: ErrorInfo = {
        text: 'Die Datei befindet sich bereits auf dem Server.',
        fileName: response.file.name,
      };
      err.push(error);
    }
    if (testUploadSuccess === 'Success') {
      return err;
    }
    return err;
  }
  const error: ErrorInfo = {
    err: response.xhr.status,
    text: response.xhr.statusText,
    fileName: response.file.name,
  };
  err.push(error);
  return err;
};

export const xmlHttpRequestAnalyzer = (requests?: XMLHttpRequestWithFileName[]) => {
  if (isNotNullOrUndefined(requests) && Array.isArray(requests)) {
    if (requests.length > 0) {
      requests.map((request: XMLHttpRequestWithFileName) => {
        return checkResponse(request);
      });
    }
    return err;
  }
  err.push({ text: 'No response from Server' });
  return err;
};
