import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';

type Props = {};

const ResetUploadContainer = (_props: Props) => {
  const refreshPage = () => {
    window.location.reload();
  };

  const { t } = useTranslation('translation');
  return (
    <Button size="md" onClick={refreshPage}>
      {t('upload.uploadMore')}
    </Button>
  );
};

export default ResetUploadContainer;
