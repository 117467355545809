import React from 'react';

import colors from '../colors';

const sic = {
  path: (
    <>
      <path fill={colors.shipBlue[500]} d="M391.8 199.7H.2v55l195.8 55 195.8-55v-55z" />

      <path fill={colors.shipBlue[500]} d="M305.1 99.9h86.6v109.8h-86.6zM.2 99.9h86.6v109.8H.2z" />

      <path fill={colors.shipRed[500]} d="M101.9.4h188.2v188.2H101.9V.4z" />

      <path
        fill={colors.white}
        d="M198 158.8l-2.8 12.4c-.3 1.1-.8 2.2-1.5 3.2-.7.9-1.7 1.7-2.8 2.3-1.1.6-2.4.9-3.7 1-1.3.1-2.6-.1-3.9-.4-13.8-4.5-26.4-11.9-36.8-21.5-9.7-8.8-17.2-19.3-22.1-30.9-5.4-12.5-7.5-25.8-6.1-38.8.1-1.2.5-2.3 1.2-3.3.6-1 1.5-1.8 2.6-2.5 1-.7 2.2-1.1 3.5-1.3 1.3-.2 2.6-.2 3.9.1l13.8 3.1c2.2.5 4.2 1.7 5.6 3.3 1.4 1.7 2.1 3.7 2 5.8-.3 4.1-.1 8.2.6 12.3.3 1.6.1 3.2-.6 4.7s-1.9 2.7-3.4 3.5l-7 3.9c4.2 11.8 11.9 22.4 22.1 30.9l7-3.9c1.5-.8 3.2-1.2 5-1.2 1.8.1 3.6.6 5.1 1.5 3.9 2.3 8 4.3 12.3 5.8 2.2.8 4 2.2 5.1 4.1 1.1 1.8 1.4 3.9.9 5.9z"
      />

      <path
        fill="none"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12.322"
        d="M240.8 119.8v-9c0-4.8-1.9-9.3-5.3-12.7-3.4-3.4-7.9-5.3-12.7-5.3h-35.9c-4.8 0-9.3 1.9-12.7 5.3-3.4 3.4-5.3 7.9-5.3 12.7v9M204.9 74.9c9.9 0 17.9-8 17.9-17.9 0-9.9-8-17.9-17.9-17.9-9.9 0-17.9 8-17.9 17.9-.1 9.9 8 17.9 17.9 17.9z"
      />

      <path
        fill="none"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.798"
        d="M267.7 119.8v-9c0-4-1.3-7.8-3.8-11-2.4-3.1-5.8-5.4-9.7-6.4M236.3 39.6c3.9 1 7.3 3.2 9.7 6.4 2.4 3.1 3.8 7 3.8 11s-1.3 7.9-3.8 11c-2.4 3.1-5.9 5.4-9.7 6.4"
      />
    </>
  ),
  viewBox: '0 0 392 310',
};

export default sic;
