import React from 'react';
import { Alert, Box, CircularProgress } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  shouldDeidentify: boolean;
  status: string;
};

const CurrentStatus = ({ shouldDeidentify, status }: Props) => {
  const { t } = useTranslation('translation');

  if (status === 'running') {
    return (
      <Box my="2">
        <Alert status="info">
          <CircularProgress isIndeterminate={true} color="blue" mr={1} size="1.2rem" />
          {t('uploadstatus.running')}
        </Alert>
      </Box>
    );
  }

  if (status === 'done') {
    return (
      <Box my="2">
        <Alert status="success">
          <CircularProgress value={100} color="green" mr={1} size="1.2rem" />
          {t('uploadstatus.done')}
        </Alert>
      </Box>
    );
  }

  if (status === 'uploading') {
    return (
      <Box my="2">
        <Alert status="info">
          <CircularProgress isIndeterminate={true} color="blue" mr={1} size="1.2rem" />
          {shouldDeidentify ? t('uploadstatus.uploading') : t('uploadstatus.uploadingWithoutDeident')}
        </Alert>
      </Box>
    );
  }

  return null;
};

export default CurrentStatus;
