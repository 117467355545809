import { theme as defaultChakraTheme } from '@chakra-ui/core';

const colors = {
  ...defaultChakraTheme.colors,
  app: {} as Record<string, string>,
  error: defaultChakraTheme.colors.red[500],
  gray: {
    50: '#FFF6F6',
    100: '#F7FAFC',
    200: '#EDF2F7',
    300: '#E2E8F0',
    400: '#CBD5E0',
    500: '#A0AEC0',
    600: '#718096',
    700: '#4A5568',
    800: '#2D3748',
    900: '#1A202C',
  },
  shipRed: {
    50: '#FFF6F6',
    100: '#FFF2F2',
    200: '#FEE0E0',
    300: '#FECDCD',
    400: '#FDA7A7',
    500: '#FC8181',
    600: '#E37474',
    700: '#974D4D',
    800: '#713A3A',
    900: '#4C2727',
  },
  shipBlue: {
    50: '#F0F1F2',
    100: '#EAEBED',
    200: '#CBCDD1',
    300: '#ABAFB6',
    400: '#6C737F',
    500: '#2D3748',
    600: '#293241',
    700: '#1B212B',
    800: '#141920',
    900: '#0E1116',
  },
  brand: {
    slate: {
      50: '#F1F1F1',
      100: '#ECECEC',
      200: '#CFCECE',
      300: '#B1B1B1',
      400: '#777777',
      500: '#3D3C3C',
      600: '#373636',
      700: '#252424',
      800: '#1B1B1B',
      900: '#121212',
    },
    heh: {
      primary: {
        50: '#F8EFF0',
        100: '#F5E9EA',
        200: '#E6C7CA',
        300: '#D7A6AA',
        400: '#B9636A',
        500: '#9B202A',
        600: '#8C1D26',
        700: '#5D1319',
        800: '#460E13',
        900: '#2F0A0D',
      },
      secondary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
    },
    jkw: {
      primary: {
        50: '#F9FAF1',
        100: '#F6F8EB',
        200: '#E9EDCC',
        300: '#DCE2AD',
        400: '#C1CD70',
        500: '#A7B732',
        600: '#96A52D',
        700: '#646E1E',
        800: '#4B5217',
        900: '#32370F',
      },
      secondary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
    },
    rlk: {
      primary: {
        50: '#F3F9F6',
        100: '#EEF6F3',
        200: '#D5E9E0',
        300: '#BCDCCE',
        400: '#89C1A9',
        500: '#57A784',
        600: '#4E9677',
        700: '#34644F',
        800: '#274B3B',
        900: '#1A3228',
      },
      secondary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
    },
    uke: {
      primary: {
        50: '#EDF1F7',
        100: '#E6EBF4',
        200: '#BFCCE3',
        300: '#99ADD1',
        400: '#4D70AF',
        500: '#00338D',
        600: '#002E7F',
        700: '#001F55',
        800: '#00173F',
        900: '#000F2A',
      },
      secondary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
    },
    ume: {
      primary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
      secondary: {
        50: '#EFF4F6',
        100: '#E9F0F3',
        200: '#C7D9E0',
        300: '#A6C2CD',
        400: '#6395A8',
        500: '#206782',
        600: '#1D5D75',
        700: '#133E4E',
        800: '#0E2E3B',
        900: '#0A1F27',
      },
    },
    wtz: {
      primary: {
        50: '#FCFAF0',
        100: '#FAF8E9',
        200: '#F3ECC8',
        300: '#EBE1A7',
        400: '#DCCB65',
        500: '#CDB423',
        600: '#B9A220',
        700: '#7B6C15',
        800: '#5C5110',
        900: '#3E360B',
      },
      secondary: {
        50: '#FEF4ED',
        100: '#FDF0E6',
        200: '#FAD9BF',
        300: '#F7C199',
        400: '#F2934D',
        500: '#EC6500',
        600: '#D45B00',
        700: '#8E3D00',
        800: '#6A2D00',
        900: '#471E00',
      },
    },
  },
};

export default colors;
