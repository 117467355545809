import React from 'react';

import FormikOptions from './FormikOptionsForm';

// Types
import { DeidentUserInputOptions } from '../../../types/types';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  setDeidentUserInputOptions: React.Dispatch<React.SetStateAction<DeidentUserInputOptions>>;
  shouldDeidentify: boolean;
};

const OptionsForm = ({ deidentUserInputOptions, setDeidentUserInputOptions, shouldDeidentify }: Props) => {
  if (shouldDeidentify) {
    return <FormikOptions deidentUserInputOptions={deidentUserInputOptions} setDeidentUserInputOptions={setDeidentUserInputOptions} />;
  }
  return null;
};

export default OptionsForm;
