import React from 'react';
import { Box, Flex, Heading, List, ListItem } from '@chakra-ui/core';

import LanguageToggle from '../LanguageToggle';
import NavigationAppButton from './NavigationAppButton/NavigationAppButton';
import NavigationHamburgerButton from './NavigationHamburgerButton/NavigationHamburgerButton';
import NavigationMobileMenu from './NavigationMobileMenu/NavigationMobileMenu';
import useToggle from '../../utils/hooks/useToggle';

// Types
import { BoxProps } from '@chakra-ui/core';
import { ReactNode } from 'react';

export type NavigationProps = {
  appName: string | ReactNode;
  children?: ReactNode;
  icon?: string | ReactNode;
  isFixed?: boolean;
} & BoxProps;

const Navigation: React.FC<NavigationProps> = React.forwardRef<any, NavigationProps>(
  ({ appName, children, icon = 'ship', isFixed, ...rest }: NavigationProps, ref) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle();
    const childrenCount = React.Children.count(children);

    const wrappedChildren = React.Children.map(children, (child, index) => <ListItem key={`nav${index.toString()}`}>{child}</ListItem>);

    return (
      <Box
        alignItems="center"
        as="header"
        bg="background"
        shadow="lg"
        d="flex"
        minH="61px"
        position={isFixed ? 'fixed' : 'relative'}
        ref={ref}
        w={isFixed ? '100%' : undefined}
        zIndex={isFixed ? 50 : undefined}
        {...rest}
      >
        <Flex alignItems="baseline" as="nav" flexDirection={['column', 'row']} minH="61px" px={6} py={3}>
          {typeof appName === 'string' && (
            <Heading as="h1" fontSize="lg" fontWeight="semibold" mt="7.5px">
              {appName}
            </Heading>
          )}
          {!!appName && typeof appName !== 'string' && appName}
          <Box d={['none', 'block']} ml={8}>
            <List alignItems="center" d="flex" flexDirection="row">
              {wrappedChildren}
            </List>
          </Box>

          <NavigationMobileMenu isMenuOpen={isMenuOpen} userDisplayName="User">
            {wrappedChildren}
          </NavigationMobileMenu>
        </Flex>
        <Flex w="100%" d={['inline-block', 'flex']}>
          <Box d="flex" m="auto" marginRight={[20, 10]} float={['right', 'none']} alignItems="center" justifyContent="center">
            <LanguageToggle />
          </Box>
        </Flex>

        <Box d={[childrenCount > 1 ? 'none' : 'block', 'block']}>
          <NavigationAppButton icon={icon} isMenuOpen={isMenuOpen} userDisplayName="User" />
        </Box>
        <Box d={[childrenCount > 0 ? 'block' : 'none', 'none']}>
          <NavigationHamburgerButton isMenuOpen={isMenuOpen} onClick={toggleIsMenuOpen} />
        </Box>
      </Box>
    );
  },
);

Navigation.displayName = 'Navigation';

export default Navigation;
