export const isHashLink = (url?: string | null) => {
  if (!url) {
    return false;
  }

  return url.startsWith('#');
};

export const isInternalLink = (url?: string | null) => {
  if (!url) {
    return false;
  }

  return url.startsWith('/');
};
