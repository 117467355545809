import React, { useEffect } from 'react';
import { Box, Progress } from '@chakra-ui/core';

type Props = {
  value: number;
};

const ProgressBar = ({ value }: Props) => {
  const [color, setColor] = React.useState<string>('shipRed');

  useEffect(() => {
    if (value === 100) {
      setColor('green');
    }
  }, [value]);

  return (
    <Box my="2">
      <Progress hasStripe={true} isAnimated={true} size="md" value={value} color={color} />
    </Box>
  );
};

export default ProgressBar;
