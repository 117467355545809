import {
  DicomDeidentifier,
  Tag,
  RetainDeviceIdentOption,
  RetainLongModifDatesOption,
  RetainSafePrivateOption,
  RetainPatientCharsOption,
  formatDate,
  parseDate,
} from '@umessen/dicom-deidentifier';

import { getCurrentDate } from './utils';
import { userInputFileName } from '../../files/fileName';
import { toUid } from '@umessen/dicom-deidentifier';
import { v4 as uuidv4 } from 'uuid';

import { FormData } from '../../types/types';

// Types

import { StudyMetaInformations } from '../../types/types';

/* eslint @typescript-eslint/no-var-requires: "off" */
const dummyDicomFile = require('../../files/dummyData.dcm');

const buildDicomFile = (dicomData: Uint8Array, fileName: string, fileType: string) => {
  const blob = new Blob([dicomData], { type: 'application/dicom' });
  const file = new File([blob], fileName, { type: fileType });
  return file;
};

export const writeUserInputFile = async (
  dicomFile: File,
  customJSONFormInput: string,
  studyMetaData: StudyMetaInformations,
  studyUID: string,
  userInputPatientID: string,
  shouldDeidentify: boolean,
) => {
  return new Promise<Uint8Array>((resolve, reject) => {
    const seriesInstanceID = toUid(uuidv4());
    const SOPInstanceUID = toUid(uuidv4());
    const read = new FileReader();
    read.readAsArrayBuffer(dicomFile);
    read.onload = async () => {
      const byteArray = new Uint8Array(read.result as ArrayBuffer);

      try {
        const deidentifier = new DicomDeidentifier({
          profileOptions: [RetainDeviceIdentOption, RetainLongModifDatesOption, RetainSafePrivateOption, RetainPatientCharsOption],
          getReferenceTime: dictionary => parseDate(dictionary['00080030'].Value[0] as string, 'TM'),
          getReferenceDate: () => new Date('1967-10-12'),
          dummies: {
            default: 'removed',
            lookup: {
              [Tag.forName('PatientName')]: shouldDeidentify ? 'removed' : studyMetaData.patientName,
              [Tag.forName('StudyID')]: studyUID ?? 'removed',
              [Tag.forName('PatientID')]: userInputPatientID.length > 0 ? userInputPatientID : studyMetaData.patientID,
            },
          },
          specialHandlers: [
            (element, _options) => {
              if (element.getTag() === '00104000') {
                element.setValue([customJSONFormInput]);
                return true;
              }
              // Content date
              if (element.getTag() === '00080023') {
                element.setValue([formatDate(getCurrentDate(), element.getVR() as any)]);
                return true;
              }
              if (element.getTag() === '00080022') {
                element.setValue([formatDate(getCurrentDate(), element.getVR() as any)]);
                return true;
              }
              if (element.getTag() === '00080021') {
                element.setValue([formatDate(getCurrentDate(), element.getVR() as any)]);
                return true;
              }
              // Study Instance UID (0020,000D)
              if (element.getTag() === '0020000D') {
                element.setValue([studyUID]);
                return true;
              }
              // Series Instance UID (0020,000E)
              if (element.getTag() === '0020000E') {
                element.setValue([seriesInstanceID]);
                return true;
              }
              // SOP Instance UID (0008,0018)
              if (element.getTag() === '00080018') {
                element.setValue([SOPInstanceUID]);
                return true;
              } else {
                return false;
              }
            },
            // Fix for https://gitlab.ume.de/ship/app-dicom-uploader/-/issues/18
            element => {
              if (element.getTag() === '00083010' && element.getVR() === 'UN') {
                console.warn(
                  "Skipped (0008,3010) 'Irradiation Event UID' because its value representation is UNKNOWN (VR=UN). This will cause trouble otherwise because VR=UI is expected for this tag.",
                );
                return true;
              } else {
                return false;
              }
            },
          ],
        });

        const result = deidentifier.deidentify(new Uint8Array(byteArray));
        resolve(result);
      } catch (ex) {
        // eslint-disable no-console, no-control-regex
        console.log('Error parsing byte stream', ex);
      }
    };
    read.onerror = reject;
  });
};

export const makeDcmFileWithUserInput = async (
  userInput: FormData,
  studyMetaData: StudyMetaInformations,
  studyUID: string,
  shouldDeidentify: boolean,
) => {
  const payload = JSON.stringify(userInput);
  const userInputPatientID = userInput.fullPatientID ?? '';

  function makeRequest(): Promise<File> {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open('get', dummyDicomFile, true);
      xhr.responseType = 'blob';
      xhr.onload = async function () {
        if (this.status >= 200 && this.status < 300) {
          const blob = xhr.response;
          // Write user input in dcm file
          const deidentifyedByteArray = await writeUserInputFile(
            blob,
            payload,
            studyMetaData,
            studyUID,
            userInputPatientID,
            shouldDeidentify,
          );
          // build file
          const buildDcmFile = await buildDicomFile(deidentifyedByteArray, userInputFileName, 'application/dicom');
          resolve(buildDcmFile);
        } else {
          reject(new Error(`Something bad happened ${xhr.statusText}`));
        }
      };
      xhr.onerror = function () {
        reject(new Error(`Something bad happened ${xhr.statusText}`));
      };
      xhr.send();
    });
  }
  return makeRequest()
    .then(function (dcmFile) {
      return dcmFile;
    })
    .catch(function (err) {
      console.error('There was an error!', err.statusText);
      return null;
    });
};
