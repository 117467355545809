import React from 'react';

import colors from '../colors';

const patientDashboard = {
  path: (
    <>
      <path d="M503 261H0v70l251 71 252-71v-70z" fill={colors.shipBlue[500]} />

      <path d="M392 133h111v141H392zM0 133h111v141H0z" fill={colors.shipBlue[500]} />

      <path d="M131 0h241v241H131V0z" fill={colors.shipRed[500]} />

      <path d="M284 96a36 36 0 11-37-36v36h37z" fill={colors.white} />

      <path d="M254 53a36 36 0 0137 36h-37V53z" fill={colors.white} />

      <path
        d="M306 201v-14a28 28 0 00-27-28h-56a28 28 0 00-28 28v14"
        fill="transparent"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="19.1"
      />
    </>
  ),
  viewBox: '0 0 503 403',
};

export default patientDashboard;
