import React from 'react';

import colors from '../colors';

const questionnaire = {
  path: (
    <>
      <path d="M392 200H0v55l196 55 196-55v-55z" fill={colors.shipBlue[500]} />

      <path d="M305 100h87v110h-87zM0 100h87v110H0z" fill={colors.shipBlue[500]} />

      <path d="M102 0h188v188H102V0z" fill={colors.shipRed[500]} />

      <path d="M217 52h11a11 11 0 0110 11v73a11 11 0 01-10 11h-64a11 11 0 01-10-11V63a11 11 0 0110-11h11" fill={colors.white} />

      <path
        d="M217 52h11a11 11 0 0110 11v73a11 11 0 01-10 11h-64a11 11 0 01-10-11V63a11 11 0 0110-11h11"
        fill="none"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.8"
      />

      <path
        d="M212 42h-31c-3 0-6 2-6 5v10c0 3 3 6 6 6h31c3 0 6-3 6-6V47c0-3-3-5-6-5z"
        fill={colors.shipRed[500]}
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.8"
      />

      <path d="M211 73c1-2 4-3 6-3l6 3c2 1 3 4 3 6s-1 5-3 6l-40 41-17 4 4-17 41-40z" fill={colors.shipRed[500]} />
    </>
  ),
  viewBox: '0 0 392 310',
};

export default questionnaire;
