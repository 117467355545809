import React from 'react';
import { Flex, Spinner } from '@chakra-ui/core';

import Card from '../SSCard/SSCard';

// Types
import { CardProps } from '../../../types/Card';

type Props = {} & CardProps;

const CardLoading = (props: Props) => {
  return (
    <Card {...props}>
      <Flex align="center" justify="center" minH="15vh">
        <Spinner />
      </Flex>
    </Card>
  );
};

export default CardLoading;
