import { theme as defaultChakraTheme } from '@chakra-ui/core';

import coronaDashboard from './coronaDashboard';
import editPerson from './editPerson';
import landingPage from './landingPage';
import patientDashboard from './patientDashboard';
import questionnaire from './questionnaire';
import questionnaireViewer from './questionnaireViewer';
import ship from './ship';
import sic from './sic';
import skysail from './skysail';
import terminologist from './terminologist';
import ume from './ume';
import vmManager from './vmManager';

// TODO: Is this better than specifiying it in the skysail provider?

const icons = {
  ...defaultChakraTheme.icons,
  'corona-dashboard': coronaDashboard,
  editPerson,
  'landing-page': landingPage,
  'patient-dashboard': patientDashboard,
  questionnaire,
  'questionnaire-viewer': questionnaireViewer,
  ship,
  sic,
  skysail,
  terminologist,
  ume,
  'vm-manager': vmManager,
};

export default icons;
