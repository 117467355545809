import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';

import { checkIfKeyHasValue } from '../../utils/object/checkIfKeyHasValue';
import { deidentifyFile } from '../../utils/deidentify/deidentify';
import { getStudyUID } from '../../utils/dicomParse/parseData';
import { makeDcmFileWithUserInput } from '../../utils/JsonToDicom/addPrivateDataToDicom';

import { FileWithPath } from 'react-dropzone';
import { DeidentUserInputOptions, FormData, StudyMetaInformations } from '../../types/types';

// Generate additional dicom file from user form input
const userInputDcmFile = async (
  studyUIDList: React.MutableRefObject<string[]>,
  studyMetaData: StudyMetaInformations | string,
  setDeidentifyedFiles: Function,
  userFormInput: FormData,
  shouldDeidentify: boolean,
) => {
  if (typeof studyMetaData !== 'string') {
    for (const studyUID of studyUIDList.current) {
      /* eslint-disable no-await-in-loop */
      const file = await makeDcmFileWithUserInput(userFormInput, studyMetaData, studyUID, shouldDeidentify);
      if (isNotNullOrUndefined(file)) {
        setDeidentifyedFiles((oldArray: File[]) => [...oldArray, file]);
      }
    }
    // const file = await makeDcmFileWithUserInput(userFormInput, studyMetaData, shouldDeidentify);
    // if (isNotNullOrUndefined(file)) {
    //   setDeidentifyedFiles((oldArray: File[]) => [...oldArray, file]);
    // }
  }
};

const deidentifyCheckedFiles = async (
  checkedFiles: FileWithPath[],
  deidentUserInputOptions: DeidentUserInputOptions,
  userFormInput: FormData,
) => {
  const deFiles: Promise<FileWithPath | null>[] = checkedFiles.map((file: FileWithPath) => {
    return deidentifyFile(file, deidentUserInputOptions, userFormInput);
  });

  // Filter not valid dicom files
  const filteredDeidentifyedFiles = Promise.all(deFiles).then(res => {
    return res.filter(file => file !== null);
  });

  return filteredDeidentifyedFiles;
};

// const addPrefixToCheckedFiles = async (checkedFiles: FileWithPath[], userFormInput: FormData) => {
//   const deFiles: Promise<FileWithPath | null>[] = checkedFiles.map((file: FileWithPath) => {
//     return addPrefixToPatientId(file, userFormInput);
//   });

//   // Filter not valid dicom files
//   const filteredFiles = Promise.all(deFiles).then(res => {
//     return res.filter(file => file !== null);
//   });

//   return filteredFiles;
// };

export const buildDeidentPayload = async (
  studyUIDList: React.MutableRefObject<string[]>,
  checkedFiles: FileWithPath[],
  deidentUserInputOptions: DeidentUserInputOptions,
  setDeidentifyedFiles: Function,
  setShouldUpload: Function,
  userFormInput: FormData,
  setAllowUpload: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return deidentifyCheckedFiles(checkedFiles, deidentUserInputOptions, userFormInput).then(payload => {
    if (payload.length > 0) {
      setDeidentifyedFiles((oldArray: FileWithPath[]) => [...oldArray, ...payload]);
      getStudyUID(checkedFiles[0]).then(studyUID => {
        userInputDcmFile(studyUIDList, studyUID, setDeidentifyedFiles, userFormInput, true).then(() => {
          setShouldUpload(true);
          setAllowUpload(true);
          return true;
        });
      });
    }
    return false;
  });
};

export const buildNormalPayload = async (
  studyUIDList: React.MutableRefObject<string[]>,
  checkedFiles: FileWithPath[],
  setDeidentifyedFiles: Function,
  setShouldUpload: Function,
  userFormInput: FormData,
) => {
  setDeidentifyedFiles((oldArray: File[]) => [...oldArray, ...checkedFiles]);

  // Create additional dicom file with userInput if there is text in "kommentar"
  if (isNotNullOrUndefined(userFormInput.kommentar) && checkIfKeyHasValue(userFormInput)) {
    getStudyUID(checkedFiles[0]).then(studyUID => {
      userInputDcmFile(studyUIDList, studyUID, setDeidentifyedFiles, userFormInput, false).then(() => {
        setShouldUpload(true);
      });
    });
  } else {
    setShouldUpload(true);
  }
};
