import sharedColors from '../shared/colors';

const colors = {
  ...sharedColors,
  background: sharedColors.white,
  highlight: sharedColors.shipRed[500],
  pageBackground: '#CBD5E0',
  table: {
    head: {
      background: sharedColors.gray[200],
      divider: sharedColors.gray[300],
      text: sharedColors.gray[700],
    },
    row: {
      background: sharedColors.white,
      divider: sharedColors.blackAlpha[100],
      hover: sharedColors.gray[300],
      stripedBackgroundEven: sharedColors.gray[200],
      stripedBackgroundOdd: sharedColors.gray[100],
      text: sharedColors.gray[800],
    },
  },
  text: {
    primary: sharedColors.gray[800],
    secondary: sharedColors.gray[600],
  },
  input: {
    background: sharedColors.gray[100],
    hover: sharedColors.gray[200],
  },
};

export default colors;
