import React from 'react';

import colors from '../colors';

const questionnaireViewer = {
  path: (
    <>
      <path d="M391.8 199.7H.2v55l195.8 55 195.8-55v-55z" fill={colors.shipBlue[500]} />

      <path d="M305.1 99.9h86.6v109.8h-86.6zM.2 99.9h86.6v109.8H.2z" fill={colors.shipBlue[500]} />

      <path d="M102 0h188.2v188.2H102V0z" fill={colors.shipRed[500]} />

      <path
        d="M217.1 52h10.5c2.8 0 5.5 1.1 7.5 3.1s3.1 4.7 3.1 7.5v73.8c0 2.8-1.1 5.5-3.1 7.5s-4.7 3.1-7.5 3.1h-63.3c-2.8 0-5.5-1.1-7.5-3.1s-3.1-4.7-3.1-7.5V62.6c0-2.8 1.1-5.5 3.1-7.5s4.7-3.1 7.5-3.1h10.5"
        fill={colors.white}
      />

      <path
        d="M217.1 52h10.5c2.8 0 5.5 1.1 7.5 3.1s3.1 4.7 3.1 7.5v73.8c0 2.8-1.1 5.5-3.1 7.5s-4.7 3.1-7.5 3.1h-63.3c-2.8 0-5.5-1.1-7.5-3.1s-3.1-4.7-3.1-7.5V62.6c0-2.8 1.1-5.5 3.1-7.5s4.7-3.1 7.5-3.1h10.5"
        fill="none"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.779"
      />

      <path
        d="M212.3 41.5h-31.6c-2.9 0-5.3 2.4-5.3 5.3v10.5c0 2.9 2.4 5.3 5.3 5.3h31.6c2.9 0 5.3-2.4 5.3-5.3V46.8c-.1-2.9-2.4-5.3-5.3-5.3z"
        fill={colors.shipRed[500]}
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.779"
      />

      <path
        d="M206.7 105.3c0 2.8-1.1 5.5-3.1 7.5s-4.7 3.1-7.5 3.1-5.5-1.1-7.5-3.1-3.1-4.7-3.1-7.5 1.1-5.5 3.1-7.5 4.7-3.1 7.5-3.1 5.5 1.1 7.5 3.1 3.1 4.7 3.1 7.5z"
        fill={colors.shipRed[500]}
      />

      <path
        clipRule="evenodd"
        d="M162.2 105.3S174.9 82 196.1 82s33.9 23.3 33.9 23.3-12.7 23.3-33.9 23.3-33.9-23.3-33.9-23.3zm33.9 14.8c3.9 0 7.7-1.6 10.5-4.3 2.8-2.8 4.3-6.6 4.3-10.5 0-3.9-1.6-7.7-4.3-10.5-2.8-2.8-6.6-4.3-10.5-4.3s-7.7 1.6-10.5 4.3c-2.8 2.8-4.3 6.6-4.3 10.5 0 3.9 1.6 7.7 4.3 10.5 2.8 2.8 6.6 4.3 10.5 4.3z"
        fill={colors.shipRed[500]}
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 392 310',
};

export default questionnaireViewer;
