import React from 'react';

import colors from '../colors';

const vmManager = {
  path: (
    <>
      <path fill={colors.shipBlue[500]} d="M3099 1607.4H0v435.2l1549.5 435.2L3099 2042.6v-435.2z" />

      <path fill={colors.shipBlue[500]} d="M2413.2 817.3h685.3v868.8h-685.3zM0 817.3h685.3v868.8H0z" />

      <path fill={colors.shipRed[500]} d="M806 0h1486v1486H806V0z" />

      <path
        fill={colors.white}
        d="M1136.4 529.6v477.1l388.3 232.4V765.2l-388.3-235.6zM1962.6 529.6v475.8l-385 233.6V769l385-239.4zM1546.3 248l404.5 240-404.5 240-398.2-240 398.2-240z"
      />
    </>
  ),
  viewBox: '0 0 3099 2478',
};

export default vmManager;
