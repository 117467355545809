import React from 'react';
import { Alert, AlertIcon, Box } from '@chakra-ui/core';

import { ErrorInfo } from '../../types/types';

type Props = {
  error: ErrorInfo;
};

const UploadError = ({ error }: Props) => {
  return (
    <Alert status="warning">
      <AlertIcon />
      <Box>
        <Box display="inline">{error.text}</Box>
        <Box ml={1} display="inline-block">
          Betroffene Datei:
        </Box>
        <Box ml={1} display="inline" fontWeight="700">
          {error.fileName ?? ''}
        </Box>
        <Box ml={1} display="inline">
          {error.err ?? ''}
        </Box>
      </Box>
    </Alert>
  );
};

export default UploadError;
