import React from 'react';
import { Alert, AlertIcon } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  uploadErrorText?: string;
};

const UploadServerError = ({ uploadErrorText }: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Alert status="error">
      <AlertIcon />
      {t('uploadstatus.error')} {uploadErrorText}
    </Alert>
  );
};

export default UploadServerError;
