import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet-async';

// import i18n (needs to be bundled ;))
import './i18n';

import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import theme from './theme/skysailLight';
import ContextProvider from './utils/context/ContextProvider';
import envConfig from './config/env';
import RoutesProvider from './config/routing/RoutesProvider';

import './icons';
import 'typeface-roboto';
import 'typeface-roboto-mono';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <ContextProvider>
        <Helmet titleTemplate={`%s | ${envConfig.appName}`} />

        <RoutesProvider />
      </ContextProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
