const routes = {
  error: {
    path: '/fehler',
    title: 'Etwas ist schief gelaufen',
  },

  upload: {
    path: '/',
    title: 'Upload',
  },

  uploadWithPrefix: {
    path: '/:prefix',
    title: 'Upload',
  },

  about: {
    path: '/site/about',
    title: 'About',
  },

  notAllowed: {
    path: '/401',
    title: 'Sie sind nicht berechtigt diese Seite aufzurufen',
  },

  notFound: {
    path: '/404',
    title: 'Page or Resource not found.',
  },
};

export default routes;
