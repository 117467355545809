import { XMLHttpRequestWithFileName } from '../../types/types';

const uploadOneFile = (file: File) => {
  // Orthanc server adress e.g. http://localhost:8080/ul
  let serverUrl = '';
  const environment = process.env.NODE_ENV;

  switch (environment) {
    case 'development':
      serverUrl = 'http://localhost:8080/ul';
      break;
    case 'production':
      serverUrl = '/ul';
      break;
    default:
      serverUrl = '/ul';
  }

  const xhr = new XMLHttpRequest();

  return new Promise<XMLHttpRequestWithFileName>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }
      if (xhr.status !== 200) {
        // error
        reject(new Error(`${xhr.status.toString()} Datei: ${file.name}`));
      }
      if (xhr.readyState === 4) {
        resolve({ xhr, file });
      }
      // success
    };
    xhr.open('POST', serverUrl, true);
    xhr.send(file);
  });
};

const promiseAllInBatches = async (
  task: Function,
  items: File[],
  batchSize: number,
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>,
) => {
  let position = 0;
  let results: any = [];
  let percent = 0;
  /* eslint-disable no-await-in-loop */
  while (position < items.length) {
    const itemsForBatch = items.slice(position, position + batchSize);
    results = [...results, ...(await Promise.all(itemsForBatch.map(item => task(item))))];
    position += batchSize;
    percent = (position * 100) / items.length;
    setUploadProgress(parseInt(percent.toFixed(2), 10));
  }
  setUploadProgress(100);
  return results;
};

export const fileUpload = (files: File[], setUploadProgress: React.Dispatch<React.SetStateAction<number>>) => {
  return promiseAllInBatches(uploadOneFile, files, 5, setUploadProgress);
};
