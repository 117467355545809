import React from 'react';

import Formik from './FormikForm';

type Props = {
  isValid: boolean;
  setIsValid: Function;
  setUserFormInput: Function;
  shouldDeidentify: boolean;
};

const UserInputForm = ({ isValid, setIsValid, setUserFormInput, shouldDeidentify }: Props) => {
  return <Formik setUserFormInput={setUserFormInput} isValid={isValid} setIsValid={setIsValid} shouldDeidentify={shouldDeidentify} />;
};

export default UserInputForm;
