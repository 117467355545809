import React from 'react';

import OptionsInputForm from '../OptionsInputForm';
import { DeidentUserInputOptions } from '../../../../types/types';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  setDeidentUserInputOptions: React.Dispatch<React.SetStateAction<DeidentUserInputOptions>>;
};

const FormikOptionsForm = ({ deidentUserInputOptions, setDeidentUserInputOptions }: Props) => {
  return <OptionsInputForm deidentUserInputOptions={deidentUserInputOptions} setDeidentUserInputOptions={setDeidentUserInputOptions} />;
};

export default FormikOptionsForm;
