import React from 'react';

import AppContainer from '../../container/AppContainer';
import Error from '../../components/Error';
import routes from '../../config/routing/routes';

const ErrorPage = () => {
  return (
    <AppContainer pageTitle={routes.error.title}>
      <Error />
    </AppContainer>
  );
};
export default ErrorPage;
