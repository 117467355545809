import React from 'react';

import colors from '../colors';

const skysail = {
  path: (
    <>
      <path fill={colors.shipBlue[500]} d="M173 251.3v-42.5h-36.9v42.5h-93v-42.5H6.2v65.9l83.4 23.4 83.4-23.4v-23.4z" />

      <defs>
        <path id="a" d="M6.2 126.2H173v68H6.2z" />
      </defs>

      <clipPath id="b">
        <use overflow="visible" xlinkHref="#a" />
      </clipPath>

      <g clipPath="url(#b)">
        <path fill={colors.shipRed[500]} d="M159.7 126.2H19.5l-13.3 68s52.2-5.9 83.6-5.9c32.3 0 83.2 5.9 83.2 5.9l-13.3-68z" />
      </g>

      <g>
        <defs>
          <path id="c" d="M20.8 42.7h137.3v68H20.8z" />
        </defs>

        <clipPath id="d">
          <use overflow="visible" xlinkHref="#c" />
        </clipPath>

        <g clipPath="url(#d)">
          <path fill={colors.shipRed[500]} d="M147.2 42.7H31.7l-10.9 68s42.9-3.2 68.8-3.2c26.6 0 68.6 3.2 68.6 3.2l-11-68z" />
        </g>
      </g>
    </>
  ),
  viewBox: '0 0 180 340',
};

export default skysail;
