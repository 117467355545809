import React from 'react';
import { Stack, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  count?: number;
};

const DropzoneFileCounter = ({ count }: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Stack isInline={true} my={2} textAlign="center">
      <Text color="shipRed.500" fontWeight={700}>
        {count ?? 0}
      </Text>
      <Text>{t('upload.fileCounter')}</Text>
    </Stack>
  );
};

export default DropzoneFileCounter;
