/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */

import React from 'react';

import App from '../../components/App';
import Error from '../../components/Error';
import NotFound from '../../components/NotFound';

// Types
import { AxiosError } from 'axios';
import { ErrorInfo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  fallback?: ReactNode;
};

type State = {
  has404Error: boolean;
  has500Error: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(err: AxiosError | Error) {
    if ('response' in err) {
      if (err.response?.status === 404) {
        return { has404Error: true, has500Error: false };
      }
    }

    return { has404Error: false, has500Error: true };
  }

  state = {
    has404Error: false,
    has500Error: false,
  };

  componentDidCatch(err: Error, info: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(err, info);
  }

  render() {
    const { children, fallback } = this.props;
    const { has404Error, has500Error } = this.state;

    if (has404Error) {
      if (fallback) {
        return fallback;
      }

      return (
        <App>
          <NotFound />
        </App>
      );
    }

    if (has500Error) {
      if (fallback) {
        return fallback;
      }

      return (
        <App>
          <Error />
        </App>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
