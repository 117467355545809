import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import CardLoading from '../Card/CardLoading';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isAppLoading?: boolean;
};

const App = ({ children, isAppLoading }: Props) => {
  return (
    <Flex bg="pageBackground" direction="row" minH="100vh" w="100%">
      <Box p={4} w="100%">
        {isAppLoading ? <CardLoading /> : children}
      </Box>
    </Flex>
  );
};
export default App;
