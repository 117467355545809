import React from 'react';

import AppContainer from '../../container/AppContainer';
import UploadContainer from '../../container/Upload';
import routes from '../../config/routing/routes';

const UploadPage = () => {
  return (
    <AppContainer pageTitle={routes.upload.title}>
      <UploadContainer />
    </AppContainer>
  );
};

export default UploadPage;
