import React from 'react';

type UseToggleReturn = [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>];

const useToggle = (inititalValue = false): UseToggleReturn => {
  const [value, setValue] = React.useState<boolean>(inititalValue);

  const toggleValue = () => {
    setValue(!value);
  };

  return [value, toggleValue, setValue];
};

export default useToggle;
