import { DeidentUserInputOptions } from '../../types/types';

export const buildKeepTags = (options: DeidentUserInputOptions) => {
  const keep = ['0020000E', '00080050', '00082112', '0020000D', '00080018']; // '00291000'

  for (const [key, value] of Object.entries(options)) {
    if (key === 'age' && value === false) {
      keep.push('00101010');
    }
    if (key === 'name' && value === false) {
      keep.push('00100010');
    }
    if (key === 'sex' && value === false) {
      keep.push('00100040');
    }
  }
  return keep;
};

// Some tags:
// PatientName: 'x00100010'
// PatientID:'x00100020'
// PatientBirthDate: 'x00100030'
// PatientAddress: 'x00101040'
// PregnancyStatus: 'x001021c0'
// PatientAge: 'x00101010'
// PatientSex: 'x00100040'

// InstitutionName: 'x00080080'
// InstitutionalDepartmentName: 'x00081040'
// InstitutionAddress: 'x00080081'
// ReferringPhysicianName: 'x00080090'
// StationName: 'x00081010'

// StudyInstanceUID: 'x0020000d'
// StudyDescription: 'x00081030'
// RequestedProcedureDescription: 'x00321060'
// PerformedProcedureStepDescription: 'x00400254'
// SeriesDescription: 'x0008103e'
// ProtocolName: 'x00181030'
// DeviceSerialNumber: 'x00181000'

// StudyDate: 'x00080020'
// StudyTime: 'x00080030'
// InstanceCreationDate: 'x00080012'
// InstanceCreationTime: 'x00080013'
