import React from 'react';
import { Flex } from '@chakra-ui/core';
import { animated, useSpring } from 'react-spring';

import SVGBox from '../../SVGBox';

// Types
import { SVGBoxProps } from '../../SVGBox/SVGBox';

export type NavigationHamburgerButtonProps = {
  isMenuOpen: boolean;
  onClick: () => void;
} & SVGBoxProps;

const NavigationHamburgerButton: React.FC<NavigationHamburgerButtonProps> = React.forwardRef<any, NavigationHamburgerButtonProps>(
  ({ isMenuOpen, onClick, ...rest }: NavigationHamburgerButtonProps, ref) => {
    const tomatoSpring = useSpring({
      height: !isMenuOpen ? 15 : 20,
      transform: !isMenuOpen ? 'rotate(0deg) translateY(0) translateX(0)' : 'rotate(45deg) translateY(0) translateX(0)',
      transformOrigin: '15% 35%',
    });

    const cheeseSpring = useSpring({
      height: 15,
      opacity: !isMenuOpen ? 1 : 0,
    });

    const pattySpring = useSpring({
      height: !isMenuOpen ? 15 : 20,
      transform: !isMenuOpen ? 'rotate(0deg) translateY(0) translateX(0)' : 'rotate(-45deg) translateY(0) translateX(0)',
      transformOrigin: '11% 69%',
    });

    return (
      <Flex
        as="button"
        bg="background"
        direction="row"
        h="61px"
        justifyContent="center"
        justifyItems="center"
        onClick={onClick}
        outline="none"
        position="absolute"
        pr={4}
        py={4}
        ref={ref}
        right={0}
        top={0}
        w="70px"
        zIndex={2}
      >
        <SVGBox
          color="shipRed.500"
          ml="auto"
          w="1.25rem"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          alignSelf="center"
          justifySelf="center"
          {...rest}
        >
          <animated.rect fill="currentColor" width="90" y="13" rx="8" x="5" style={tomatoSpring} />

          <animated.rect fill="currentColor" width="90" y="43" rx="8" x="5" style={cheeseSpring} />

          <animated.rect fill="currentColor" width="90" y="73" rx="8" x="5" style={pattySpring} />
        </SVGBox>
      </Flex>
    );
  },
);

NavigationHamburgerButton.displayName = 'NavigationHamburgerButton';

export default NavigationHamburgerButton;
