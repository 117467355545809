import React from 'react';

import colors from '../colors';

const editPerson = {
  path: (
    <>
      <path fill={colors.shipBlue[500]} d="M503.662 261.27H.838v70.609l251.412 70.609 251.412-70.609V261.27z" />

      <path fill={colors.shipBlue[500]} d="M392.396 133.074h111.191v140.97H392.396zM.836 133.074h111.191v140.97H.836z" />

      <path fill={colors.shipRed[500]} d="M131.613.467h241.11v241.11h-241.11V.467z" />

      <path
        d="M295.495 187.091v-13.937a27.876 27.876 0 00-27.874-27.874h-55.747A27.874 27.874 0 00184 173.154v13.937"
        fill="transparent"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="19.14"
      />

      <path
        d="M337.305 187.096V173.16a27.876 27.876 0 00-20.906-26.968M288.526 62.57a27.872 27.872 0 010 54.006"
        fill="transparent"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10.56"
      />

      <path
        d="M240.305 99.546c5.271 0 9.545-4.274 9.545-9.546 0-5.272-4.274-9.545-9.545-9.545-5.272 0-9.546 4.273-9.546 9.545s4.274 9.546 9.546 9.546z"
        fill="transparent"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.183"
      />

      <path
        d="M263.85 99.546a5.248 5.248 0 001.05 5.79l.191.191a6.351 6.351 0 011.866 4.503 6.363 6.363 0 01-1.866 4.502 6.374 6.374 0 01-4.502 1.866 6.364 6.364 0 01-4.503-1.866l-.191-.191a5.242 5.242 0 00-5.79-1.05 5.24 5.24 0 00-2.309 1.927 5.242 5.242 0 00-.873 2.877v.541a6.365 6.365 0 01-12.728 0v-.286a5.252 5.252 0 00-3.436-4.805 5.254 5.254 0 00-5.791 1.05l-.191.191a6.371 6.371 0 01-6.939 1.382 6.376 6.376 0 01-3.447-8.321 6.376 6.376 0 011.382-2.065l.191-.191a5.25 5.25 0 001.05-5.791 5.252 5.252 0 00-4.805-3.182h-.541a6.365 6.365 0 010-12.727h.287a5.249 5.249 0 004.804-3.437 5.247 5.247 0 00-1.05-5.79l-.191-.191a6.369 6.369 0 01-1.381-6.94 6.369 6.369 0 0110.386-2.065l.191.191a5.25 5.25 0 005.791 1.05h.254a5.249 5.249 0 003.182-4.805v-.54A6.361 6.361 0 01240.305 55a6.363 6.363 0 016.363 6.364v.286a5.252 5.252 0 003.182 4.804 5.25 5.25 0 005.791-1.05l.191-.19a6.37 6.37 0 014.502-1.867 6.368 6.368 0 014.502 10.871l-.191.191a5.252 5.252 0 00-1.05 5.791v.254a5.251 5.251 0 004.805 3.182h.541a6.365 6.365 0 010 12.728h-.286a5.249 5.249 0 00-4.805 3.181v0z"
        fill="transparent"
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7.183"
      />
    </>
  ),
  viewBox: '0 0 504 403',
};

export default editPerson;
