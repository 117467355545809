import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../Link';

import envConfig from '../../config/env';

import { isInternalLink } from './utils';

// Types
import { LinkProps } from '../../types/Link';

type Props = {} & LinkProps;

const SSLink = ({ asExternal, children, url, ...rest }: Props) => {
  if (!url) {
    return null;
  }

  if (isInternalLink(url) && !asExternal) {
    return (
      <RouterLink to={url}>
        <Link as="button" color={rest.isActive || !rest.isNavLink ? 'shipRed.500' : undefined} url={url} {...rest}>
          {children}
        </Link>
      </RouterLink>
    );
  }

  return (
    <Link
      asExternal={asExternal}
      color={rest.isActive || !rest.isNavLink ? 'shipRed.500' : undefined}
      url={isInternalLink(url) ? `${envConfig.baseUrl}${url}` : url}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default SSLink;
