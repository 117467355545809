import { parseDicom } from 'dicom-parser';
import { FileWithPath } from 'react-dropzone';
import isNotNullOrUndefined from '../isNotNullOrUndefined';

// const readFileAsync = async (
//   file: FileWithPath,
//   setNotAcceptedFiles: Function,
//   setFileIsNotAccepted: Function,
//   setFileIsDicom: Function,
//   setCheckedFiles: Function,
// ) => {
//   return new Promise<boolean>((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onload = () => {
//       const byteArray = new Uint8Array(reader.result as ArrayBuffer);
//       try {
//         parseDicom(byteArray);

//         setFileIsDicom(file, setCheckedFiles);
//         resolve(true);
//       } catch (err) {
//         resolve(false);
//         // eslint-disable no-console, no-control-regex
//         console.log(err);
//         setFileIsNotAccepted(file, 'falseHeader', setNotAcceptedFiles);
//       }
//       // If File is valid dicom
//     };
//     reader.readAsArrayBuffer(file);

//     reader.onerror = reject;
//   });
// };

export const isDicomFile = async (
  file: FileWithPath,
  addUniqueString: (newString: string) => void,
  setNotAcceptedFiles: Function,
  setCheckedFiles: Function,
  setFileIsNotAccepted: Function,
  setFileIsDicom: Function,
  currentShouldDeidentify: React.MutableRefObject<boolean | undefined>,
) => {
  // Create Blacklist for Secondary Captures Image Storage
  // https://dicom.nema.org/dicom/2013/output/chtml/part04/sect_B.5.html
  const sopClassUIDBlacklist = [
    '1.2.840.10008.5.1.4.1.1.7',
    '1.2.840.10008.5.1.4.1.1.7.1',
    '1.2.840.10008.5.1.4.1.1.7.2',
    '1.2.840.10008.5.1.4.1.1.7.3',
    '1.2.840.10008.5.1.4.1.1.7.4',
  ];

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const byteArray = new Uint8Array(reader.result as ArrayBuffer);
      try {
        const dataSet = parseDicom(byteArray);
        if (currentShouldDeidentify.current) {
          const sOPClassUID = dataSet.string('x00080016');
          const modality = dataSet.string('x00080060');
          const burnedInAnnotations = dataSet.string('x00280301');
          const studyUID = dataSet.string('x0020000d');
          // remove Secondary Captures
          if ((isNotNullOrUndefined(sOPClassUID) && sopClassUIDBlacklist.includes(sOPClassUID)) || modality === 'SC') {
            // remove Secondary Captures Image Storages
            setFileIsNotAccepted(file, 'Secondary Captures Image Storages', setNotAcceptedFiles);
          }
          // remove images with burned-in annotations
          else if (isNotNullOrUndefined(burnedInAnnotations) && burnedInAnnotations?.toUpperCase() === 'YES') {
            setFileIsNotAccepted(file, 'burned-in annotations', setNotAcceptedFiles);
          } else {
            setFileIsDicom(file, setCheckedFiles);
            if (isNotNullOrUndefined(studyUID)) {
              addUniqueString(studyUID);
            }
          }
        } else {
          setFileIsDicom(file, setCheckedFiles);
        }
        resolve(true);
      } catch (err) {
        resolve(false);
        // eslint-disable no-console, no-control-regex
        console.log(err);
        setFileIsNotAccepted(file, 'falseHeader', setNotAcceptedFiles);
      }
      // If File is valid dicom
    };
    reader.readAsArrayBuffer(file);

    reader.onerror = reject;
  });
};
