import React from 'react';
import { Box } from '@chakra-ui/core';

// Types
import { BoxProps } from '@chakra-ui/core';
import { SVGAttributes } from 'react';

export type SVGBoxProps = BoxProps & Pick<SVGAttributes<HTMLOrSVGElement>, 'xmlns' | 'viewBox'>;

const SVGBox: React.FC<SVGBoxProps> = React.forwardRef<any, SVGBoxProps>(({ children, ...rest }: SVGBoxProps, ref) => (
  <Box as="svg" ref={ref} xmlns="http://www.w3.org/2000/svg" {...rest}>
    {children}
  </Box>
));

SVGBox.displayName = 'SVGBox';

export default SVGBox;
