import React from 'react';
import { Box, Checkbox, Icon, Stack } from '@chakra-ui/core';
import Card from '../Card';
import { useTranslation } from 'react-i18next';

type Props = {
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  shouldDeidentify: boolean;
};

const OptionsCheckbox = ({ handleChange, shouldDeidentify }: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Card cardTitle={t('deidentOptions.CardTitle')}>
      <Box as="section" mt={4}>
        <Stack pl={0} mt={1} spacing={2} shouldWrapChildren={true}>
          <Checkbox
            borderColor="shipRed"
            variantColor="shipRed"
            isChecked={shouldDeidentify}
            id="age"
            name="age"
            onChange={handleChange}
            size="md"
          >
            {t('deidentOptions.deident')}
            <Icon name="info-outline" size="1rem" color="shipRed.500" ml={2} />
          </Checkbox>
        </Stack>
      </Box>
    </Card>
  );
};

export default OptionsCheckbox;
