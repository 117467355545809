import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/core';

import Link from '../SSLink';
import routes from '../../config/routing/routes';

// Types

type Props = {};

const NotFound = (_props: Props) => {
  return (
    <Alert
      flexDirection="column"
      justifyContent="center"
      maxW="60rem"
      mt={4}
      mx="auto"
      p={6}
      shadow="sm"
      status="error"
      variant="left-accent"
      w={['100%', '100%', '90%', '75%']}
    >
      <AlertIcon />

      <AlertTitle fontSize="lg" mb={1} mt={4}>
        404 - Seite nicht gefunden.
      </AlertTitle>

      <AlertDescription>
        Die gesuchte Seite wurde nicht gefunden.{' '}
        <Link color="primary" textDecoration="underline" url={routes.upload.path}>
          Hier geht es zurück zur Startseite.
        </Link>
      </AlertDescription>
    </Alert>
  );
};
export default NotFound;
