import React from 'react';

import ProgressBar from '../../components/ProgressBar';

type Props = {
  uploadProgress: number;
};

const Progress = ({ uploadProgress }: Props) => {
  return <ProgressBar value={uploadProgress} />;
};

export default Progress;
