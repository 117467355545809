import React from 'react';

import AppContainer from '../../container/AppContainer';
import About from '../../components/About';
import routes from '../../config/routing/routes';

const AboutPage = () => {
  return (
    <AppContainer pageTitle={routes.about.title}>
      <About />
    </AppContainer>
  );
};

export default AboutPage;
