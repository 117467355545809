import { isDicomFile } from './isDicomFile';
import pLimit from 'p-limit';
import isNotNullOrUndefined from '../isNotNullOrUndefined';

// Settings:
// const MINIMUM_FILE_SIZE = 10; // in bytes

const setFileIsDicom = (file: Blob, setCheckedFiles: Function) => {
  setCheckedFiles((prev: Array<File>) => [...prev, file]);
};

const setFileIsNotAccepted = (file: Blob, reason: string, setNotAcceptedFiles: Function) => {
  if (reason === 'falseSize') {
    setNotAcceptedFiles((oldArray: Array<File>) => [...oldArray, { file, falseSize: true }]);
  } else if (reason === 'falseHeader') {
    setNotAcceptedFiles((oldArray: Array<File>) => [...oldArray, { file, falseHeader: true }]);
  } else {
    setNotAcceptedFiles((oldArray: Array<File>) => [...oldArray, { file, reason }]);
  }
};

// const fileSizeChecker = (file: File) => {
//   if (file.size <= MINIMUM_FILE_SIZE) {
//     return false;
//   }
//   return true;
// };

export const checkFileTypes = async (
  addUniqueString: (newString: string) => void,
  files: File[],
  setNotAcceptedFiles: Function,
  setCheckedFiles: Function,
  setAllowUpload: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFileCheckinProgress: React.Dispatch<React.SetStateAction<string>>,
  currentShouldDeidentify: React.MutableRefObject<boolean | undefined>,
) => {
  // Reset
  setNotAcceptedFiles([]);
  setCheckedFiles([]);
  if (files.length !== 0 && isNotNullOrUndefined(files)) {
    setAllowUpload(false);
    setIsFileCheckinProgress('running');
    const limit = pLimit(5);
    const readAllFiles = async (AllFiles: File[]) => {
      const input = AllFiles.map(async file => {
        // The file "DICOMDIR" is a valid Dicom file. However, this only contains information about the folder structure. As a valid Dicom file, this is also attempted to be anonymized, which leads to a crash of the app. Since we do not need the file, we filter it out here.
        // TODO: Currently, only the file name is checked. Another check, for a unique meta tag or so, that identifies the file as DICOMDIR would be good.
        if (file.name?.toLocaleLowerCase() === 'dicomdir') {
          setFileIsNotAccepted(file, 'falseSize', setNotAcceptedFiles);
        } else {
          const fileContents = await limit(() =>
            // Check if it is a valid dicomfile
            // filter Secondary Captures Image Storages & burned-in annotations if it should be deidentified
            isDicomFile(
              file,
              addUniqueString,
              setNotAcceptedFiles,
              setCheckedFiles,
              setFileIsNotAccepted,
              setFileIsDicom,
              currentShouldDeidentify,
            ),
          );
          return fileContents;
        }
      });
      const results = await Promise.all(input).then(() => {
        setAllowUpload(true);
        setIsFileCheckinProgress('done');
      });
      return results;
    };

    readAllFiles(files);
  }
};
