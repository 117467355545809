import React from 'react';
import { Checkbox, Stack } from '@chakra-ui/core';

import { DeidentUserInputOptions } from '../../types/types';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
};

const OptionsCheckbox = ({ deidentUserInputOptions, handleChange }: Props) => {
  return (
    <Stack pl={6} mt={1} spacing={2} shouldWrapChildren={true}>
      <Checkbox variantColor="shipRed" isChecked={deidentUserInputOptions.age} id="age" name="age" onChange={handleChange}>
        PatientAge
      </Checkbox>
      <Checkbox variantColor="shipRed" isChecked={deidentUserInputOptions.name} id="name" name="name" onChange={handleChange}>
        PatientName
      </Checkbox>
      <Checkbox variantColor="shipRed" isChecked={deidentUserInputOptions.sex} id="sex" name="sex" onChange={handleChange}>
        PatientSex
      </Checkbox>
    </Stack>
  );
};

export default OptionsCheckbox;
