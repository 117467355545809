import dicomParser from 'dicom-parser';
import { FileWithPath } from 'react-dropzone';

import { StudyMetaInformations } from '../../types/types';

// dcm parser
export const parseFile = (byteArray: Uint8Array) => {
  // We need to setup a try/catch block because parseDicom will throw an exception
  // if you attempt to parse a non dicom part 10 file (or one that is corrupted)
  try {
    // parse byteArray into a DataSet object using the parseDicom library
    const dataSet = dicomParser.parseDicom(byteArray);

    // dataSet contains the parsed elements.  Each element is available via a property
    // in the dataSet.elements object.  The property name is based on the elements group
    // and element in the following format: xggggeeee where gggg is the group number
    // and eeee is the element number with lowercase hex characters.

    // We will get the Patient Id (0010,0020).  This is a type 2 attribute which means
    // that the element must be present but it can be empty.  If you attempt to get the string
    // for an element that has data of zero length (empty) , parseDicom will return
    // undefined so we need to check for that to avoid a script error
    const studyUID = dataSet.string('x0020000d');
    const studyID = dataSet.string('x00200010');
    const patientID = dataSet.string('x00100020');
    const patientName = dataSet.string('x00100010') ?? '';
    const studyDescription = dataSet.string('x00081030') ?? '';
    const studyDate = dataSet.string('x00080020') ?? '';
    const seriesDate = dataSet.string('x00080021') ?? '';

    if (studyUID !== undefined) {
      // Set data to state variable
      // setPatientId(patientId);
      return { studyUID, studyID, patientID, patientName, studyDescription, studyDate, seriesDate };
    }
    return 'File has no Meta';
  } catch (err) {
    // we catch the error and display it to the user
    // Set data to state variable
    // setParseError(err);
    return err as string;
  }
};

// Lookup dcm file for Study Instance UID
export const getStudyUID = async (dicomFile: FileWithPath) => {
  return new Promise<StudyMetaInformations | string>((resolve, reject) => {
    const read = new FileReader();
    read.readAsArrayBuffer(dicomFile);
    read.onload = async () => {
      const byteArray = new Uint8Array(read.result as ArrayBuffer);
      resolve(parseFile(byteArray));
    };
    read.onerror = reject;
  });
};
