import React from 'react';
import { AccordionHeader, AccordionIcon, AccordionItem, AccordionPanel, Alert, AlertIcon, Box, Text, Textarea } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
  rejectedSCFilesAsAString?: string;
};

const RejectedFilesContainer = ({ rejectedSCFilesAsAString }: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Box mt={8}>
      <Alert status="warning">
        <AlertIcon />
        {t('rejectedFiles.heading')}
      </Alert>
      <Box maxW="100%" borderWidth="1px" mt={2} p={2} rounded="lg">
        <Text fontSize="sm">{t('rejectedFiles.content')}</Text>
      </Box>
      <AccordionItem mt={2} maxW="95vw">
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Show rejected files
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel m={0} p={2}>
          <Text fontSize="sm" fontWeight="700">
            Secondary Capture (SC) images:
          </Text>
          <Textarea defaultValue={rejectedSCFilesAsAString ?? ''} />
        </AccordionPanel>
      </AccordionItem>
    </Box>
  );
};

export default RejectedFilesContainer;
