import React from 'react';

import { Box, Icon } from '@chakra-ui/core';
// import { Box, Icon, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/core';

// import ThemeSwitcher from '../../ThemeSwitcher';
// import useAppContext from '../../../context/AppContext';

// Types
import { ReactNode } from 'react';

export type NavigationAppButtonProps = {
  icon: string | ReactNode;
  isMenuOpen: boolean;
  onSignOut?: () => void;
  userDisplayName?: string | null;
};

const NavigationAppButton: React.FC<NavigationAppButtonProps> = React.forwardRef<any, NavigationAppButtonProps>(
  ({ icon, isMenuOpen }: NavigationAppButtonProps, ref) => {
    // const { isDarkModeDisabled } = useAppContext();
    // const isDarkModeDisabled = true;

    const renderedIcon = typeof icon === 'string' ? <Icon name={icon} size="2rem" /> : icon;

    return (
      <>
        <Box bottom={3} d={[isMenuOpen ? 'block' : 'none', 'none']} ml="auto" position="absolute" ref={ref} right={4} zIndex={1}>
          {renderedIcon}
        </Box>

        {/* <Box bottom={3} d="block" ml="auto" position="absolute" right={4} zIndex={1}>
          {!!userDisplayName ? (
            <Menu closeOnSelect={false}>
              <MenuButton>{renderedIcon}</MenuButton>

              <MenuList placement="right-end">
                <MenuGroup whiteSpace="nowrap">{!isDarkModeDisabled && <MenuItem>{ <ThemeSwitcher /> }</MenuItem>}</MenuGroup>

                {runningApps.length > 0 && (
                  <MenuGroup>
                    <MenuItem>
                      <Menu>
                        <MenuButton as="div" fontSize="sm">
                          Applikationen
                        </MenuButton>

                        <MenuList maxH="200px" overflowY="auto" placement="right-end">
                          {mappedRunningApps}
                        </MenuList>
                      </Menu>
                    </MenuItem>
                  </MenuGroup>
                )}

                <MenuGroup title={userDisplayName} whiteSpace="nowrap">
                  <MenuItem onClick={onSignOut}>Ausloggen</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          ) : (
            renderedIcon
          )}
        </Box> */}
      </>
    );
  },
);

NavigationAppButton.displayName = 'NavigationAppButton';

export default NavigationAppButton;
