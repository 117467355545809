import { theme as defaultChakraTheme } from '@chakra-ui/core';

import breakpoints from '../shared/breakpoints';
import colors from './colors';
import fonts from '../shared/fonts';
import icons from './icons';

// Types
import { Theme } from '../../types/theme';

const lightTheme: Theme = {
  ...defaultChakraTheme,
  breakpoints,
  colors,
  fonts,
  icons,
};

export default lightTheme;
