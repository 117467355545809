import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SWRConfig } from 'swr';

import envConfig from '../../config/env';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ContextProvider = ({ children }: Props) => {
  return (
    <Router basename={envConfig.baseUrl}>
      <HelmetProvider>
        <SWRConfig value={{ revalidateOnFocus: false, suspense: true }}>{children}</SWRConfig>
      </HelmetProvider>
    </Router>
  );
};

export default ContextProvider;
