import React from 'react';
import { withRouter } from 'react-router-dom';

import Navigation from '../../components/Navigation';
import Link from '../../components/SSLink';
import envConfig from '../../config/env';
import routes from '../../config/routing/routes';

// Types
import { RouteComponentProps } from 'react-router-dom';

type Props = {} & RouteComponentProps;

const NavigationContainer = ({ location }: Props) => {
  return (
    <Navigation appName={envConfig.appName}>
      <Link isActive={location.pathname === routes.upload.path} isNavLink={true} url={routes.upload.path}>
        {routes.upload.title}
      </Link>
      <Link isActive={location.pathname === routes.about.path} isNavLink={true} url={routes.about.path}>
        {routes.about.title}
      </Link>
    </Navigation>
  );
};

export default withRouter(NavigationContainer);
