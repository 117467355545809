import { isHashLink, isInternalLink } from '../../utils/link';
/* eslint no-shadow: "off" */
export enum RenderType {
  LinkExternal = 'LINK_EXTERNAL',
  LinkHash = 'LINK_HASH',
  LinkInternal = 'LINK_INTERNAL',
  NavLinkExternal = 'NAV_LINK_EXTERNAL',
  NavLinkHash = 'NAV_LINK_HASH',
  NavLinkInternal = 'NAV_LINK_INTERNAL',
}

type GetRenderTypeInput = {
  asExternal?: boolean;
  isNavLink?: boolean;
  url?: string | null;
};

export const getRenderType = ({ asExternal, isNavLink, url }: GetRenderTypeInput): RenderType => {
  const isUrlAHashLink = isHashLink(url);
  const isUrlInternalLink = isInternalLink(url);

  if (isNavLink) {
    if (!asExternal) {
      if (isUrlInternalLink) {
        return RenderType.NavLinkInternal;
      }

      if (isUrlAHashLink) {
        return RenderType.NavLinkHash;
      }
    }

    return RenderType.NavLinkExternal;
  }

  if (!asExternal) {
    if (isUrlInternalLink) {
      return RenderType.LinkInternal;
    }

    if (isUrlAHashLink) {
      return RenderType.LinkHash;
    }
  }

  return RenderType.LinkExternal;
};
