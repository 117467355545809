import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/core';

type Props = {
  shouldDeidentify: boolean;
  status?: string;
};

const FileList = ({ shouldDeidentify, status }: Props) => {
  const { t } = useTranslation('translation');
  if (status === 'running') {
    return (
      <Flex align="flex-start" justify="left" w="100%">
        <Flex>
          <Text fontWeight="semibold">{t('uploadstatus.running')}</Text>
        </Flex>
      </Flex>
    );
  }

  if (status === 'done') {
    return (
      <Flex align="flex-start" justify="left" w="100%">
        <Flex>
          <Text fontWeight="semibold">{t('uploadstatus.done')}</Text>
        </Flex>
      </Flex>
    );
  }

  if (status === 'uploading') {
    return (
      <Flex align="flex-start" justify="left" w="100%">
        <Flex>
          <Text fontWeight="semibold">{shouldDeidentify ? t('uploadstatus.uploading') : t('uploadstatus.uploadingWithoutDeident')}</Text>
        </Flex>
      </Flex>
    );
  }

  return null;
};

export default FileList;
