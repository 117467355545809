import React from 'react';
import { Box } from '@chakra-ui/core';

// Types
import { BoxProps } from '@chakra-ui/core';

export type FormProps = {
  noNativeValidation?: boolean;
} & BoxProps &
  React.HTMLAttributes<HTMLFormElement>;

const Form: React.FC<FormProps> = React.forwardRef<any, FormProps>(({ noNativeValidation = false, ...rest }: FormProps, ref) => {
  // @ts-ignore | `<Box />` does not have the native `novalidate` attribute in it's types, but it does forward it to the dom element.
  return <Box as="form" noValidate={noNativeValidation} ref={ref} {...rest} />;
});

Form.displayName = 'Form';

export default Form;
