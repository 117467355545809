/**
 * Returns `true` if the `input` is *not* `null` or `undefined`.
 * This is mainly used to also convince TypeScript of this fact.
 * Can be used to filter arrays: `arr.filter(isNotNullOrUndefined)`.
 */
const isNotNullOrUndefined = <T>(input: T | null | undefined): input is T => {
  return input != null && input !== null && input !== undefined;
};

export default isNotNullOrUndefined;
