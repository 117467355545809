import React from 'react';
import { Alert, AlertIcon } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {};

const UploadSuccess = (_props: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Alert status="success">
      <AlertIcon />
      {t('uploadstatus.success')}
    </Alert>
  );
};

export default UploadSuccess;
