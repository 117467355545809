import React, { forwardRef } from 'react';
import { Flex } from '@chakra-ui/core';
import Card from '../../components/Card/SSCard';

import FileUploadContainer from '../FileUpload';
import UploadUserInputFormContainer from '../UploadUserInputFormContainer';

// Types
import { FormData, DeidentUserInputOptions } from '../../types/types';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  isValid: boolean;
  setDeidentUserInputOptions: React.Dispatch<React.SetStateAction<DeidentUserInputOptions>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldDeidentify: React.Dispatch<React.SetStateAction<boolean>>;
  setUserFormInput: React.Dispatch<React.SetStateAction<FormData>>;
  shouldDeidentify: boolean;
  userFormInput: FormData;
  handleDeidentOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UploadFlexContainer = forwardRef(
  (
    {
      deidentUserInputOptions,
      isValid,
      setDeidentUserInputOptions,
      setIsValid,
      setUserFormInput,
      shouldDeidentify,
      userFormInput,
      handleDeidentOptionChange,
    }: Props,
    ref,
  ) => {
    UploadFlexContainer.displayName = 'UploadFlexContainer';
    return (
      <>
        <Flex
          maxW="90vw"
          w={['100vw', '100vw', '100vw', '90vw']}
          direction={['column', 'column', 'row', 'row']}
          justify="center"
          justifyContent="stretch"
          p="4"
        >
          <Flex align="center" mx={2} flexGrow={1} minWidth="40vw" alignItems="stretch">
            <Card mt={0}>
              <FileUploadContainer
                ref={ref}
                userFormInput={userFormInput}
                isValid={isValid}
                deidentUserInputOptions={deidentUserInputOptions}
                shouldDeidentify={shouldDeidentify}
              />
            </Card>
          </Flex>
          <UploadUserInputFormContainer
            handleDeidentOptionChange={handleDeidentOptionChange}
            deidentUserInputOptions={deidentUserInputOptions}
            shouldDeidentify={shouldDeidentify}
            isValid={isValid}
            setDeidentUserInputOptions={setDeidentUserInputOptions}
            setIsValid={setIsValid}
            setUserFormInput={setUserFormInput}
          />
        </Flex>
      </>
    );
  },
);

export default UploadFlexContainer;
