import React from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import Form from '../../../../components/Form';

import { initializedFormInputValues, initializedNormalFormInputValues } from '../../../Upload/initializedValues';
import InputFormDeidentified from '../InputFormDeidentified';
import InputFormNormal from '../InputFormNormal';

import { FormData, Params } from '../../../../types/types';
import isNotNullOrUndefined from '../../../../utils/isNotNullOrUndefined';

type Props = {
  setUserFormInput: Function;
  isValid: boolean;
  setIsValid: Function;
  shouldDeidentify: boolean;
};

const FormikForm = ({ isValid, setIsValid, setUserFormInput, shouldDeidentify }: Props) => {
  const params: Params = useParams();
  const [hasPrefix] = React.useState<boolean>(isNotNullOrUndefined(params.prefix));

  const onChangeForm = (values: FormData) => {
    const nextFormData = values;
    if (shouldDeidentify) {
      if (values.patientID?.length === 0 || values.prefix?.length === 0 || values.kommentar?.length === 0) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }

    if (!shouldDeidentify) {
      setIsValid(true);
    }

    // Any Prefix will save the Study in Chili Pax
    // "WW-" Prefix will route the Study to Wildwest Pax
    if (isNotNullOrUndefined(nextFormData.prefix) && isNotNullOrUndefined(nextFormData.patientID)) {
      nextFormData.fullPatientID = `${nextFormData.prefix}${nextFormData.patientID}`;
    } else if (!isNotNullOrUndefined(nextFormData.prefix) && isNotNullOrUndefined(nextFormData.patientID)) {
      nextFormData.fullPatientID = `WW-${nextFormData.patientID}`;
    }

    setUserFormInput(nextFormData);
  };

  // Prefill form field 'prefix'
  if (hasPrefix) {
    initializedFormInputValues.prefix = `${params.prefix}-`;
  }

  if (shouldDeidentify) {
    return (
      <Formik initialValues={initializedFormInputValues} enableReinitialize={true} onSubmit={onChangeForm}>
        {({ handleSubmit }) => (
          <Form onChange={handleSubmit}>
            <InputFormDeidentified isValid={isValid} hasPrefix={hasPrefix} />
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <Formik initialValues={initializedNormalFormInputValues} enableReinitialize={true} onSubmit={onChangeForm}>
      {({ handleSubmit }) => (
        <Form onChange={handleSubmit}>
          <InputFormNormal />
        </Form>
      )}
    </Formik>
  );
};

export default FormikForm;
