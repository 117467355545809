import { DeidentUserInputOptions, FormData } from '../../types/types';

export const initializedOptionValues: DeidentUserInputOptions = {
  age: true,
  name: true,
  sex: true,
};

export const initializedFormInputValues: FormData = {
  patientID: '',
  kommentar: '',
  fullPatientID: '',
};

export const initializedNormalFormInputValues: FormData = {
  kommentar: '',
};
