import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@chakra-ui/core';
import Card from '../../../../components/Card';
import OptionsCheckbox from '../../../../components/OptionsCheckbox';

import { DeidentUserInputOptions } from '../../../../types/types';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  setDeidentUserInputOptions: React.Dispatch<React.SetStateAction<DeidentUserInputOptions>>;
};

const OptionsInputForm = ({ deidentUserInputOptions, setDeidentUserInputOptions }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    setDeidentUserInputOptions(prev => ({
      ...prev,
      [name]: checked,
    }));
  };
  const { t } = useTranslation('translation');
  return (
    <Card cardTitle={t('deidentOptions.options.title')}>
      <Box as="section">
        <Stack spacing={3}>
          <Text fontSize="md">{t('deidentOptions.options.tagsText')}</Text>
        </Stack>
      </Box>
      <Box as="section" mt={4}>
        <OptionsCheckbox handleChange={handleChange} deidentUserInputOptions={deidentUserInputOptions} />
      </Box>
      <Box as="section" mt={4}>
        <Stack spacing={3}>
          <Text fontSize="sm">{t('deidentOptions.options.tagsHint')}</Text>
        </Stack>
      </Box>
    </Card>
  );
};

export default OptionsInputForm;
