import React from 'react';
import { Trans } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/core';

import FileListContainer from '../FileList';

import { FileWithPath } from 'react-dropzone';

type Props = {
  checkedFiles: FileWithPath[];
  getInputProps: Function;
  getRootProps: Function;
  shouldDeidentify: boolean;
  status?: string;
};

const DropZone = ({ checkedFiles, getInputProps, getRootProps, shouldDeidentify, status }: Props) => {
  return (
    <Flex align="center" justify="center" py={16} px={2} w="100%" {...getRootProps()}>
      <input {...getInputProps()} directory="" webkitdirectory="" />

      {checkedFiles.length > 0 ? (
        <FileListContainer status={status} shouldDeidentify={shouldDeidentify} />
      ) : (
        <Flex>
          <Text fontWeight="semibold">
            <Trans i18nKey="upload.uploadBox" components={{ br: <br /> }} />
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default DropZone;
