import isNotNullOrUndefined from '../isNotNullOrUndefined';

export const checkIfKeyHasValue = (obj: { [key: string]: string | undefined }) => {
  if (isNotNullOrUndefined(obj)) {
    for (const [key, value] of Object.entries(obj)) {
      if (isNotNullOrUndefined(key) && isNotNullOrUndefined(value) && value.length > 0) {
        return true;
      }
    }
  }
  return false;
};
