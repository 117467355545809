import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

import CardTitle from './CardTitle';

// Types
import { PseudoBoxProps } from '@chakra-ui/core';
import { ReactNode } from 'react';

export type CardProps = {
  cardTitle?: string | ReactNode;
  children?: ReactNode;
  isNarrow?: boolean;
} & PseudoBoxProps;

const Card: React.FC<CardProps> = React.forwardRef<any, CardProps>(({ cardTitle, children, isNarrow, ...rest }: CardProps, ref) => {
  const isNarrowProps = { maxW: '60rem', mt: 4, mx: 'auto', w: ['100%', '100%', '90%', '75%'] };

  return (
    <PseudoBox
      bg="background"
      pb={[4, 6]}
      pl={[4, 6]}
      pr={[4, 6]}
      pt={[4, 6]}
      ref={ref}
      shadow="sm"
      {...(isNarrow ? isNarrowProps : {})}
      {...rest}
    >
      {typeof cardTitle === 'string' && <CardTitle>{cardTitle}</CardTitle>}

      {!!cardTitle && typeof cardTitle !== 'string' && cardTitle}

      {children}
    </PseudoBox>
  );
});

Card.displayName = 'Card';

export default Card;
