import React from 'react';
import { Link as CUILink } from '@chakra-ui/core';

import { getRenderType } from './utils';

// Types
import { LinkProps as CUILinkProps } from '@chakra-ui/core';
import { ReactNode, SyntheticEvent } from 'react';
import { RenderType } from './utils';

export type LinkProps = {
  asExternal?: boolean;
  children?: ReactNode;
  href?: string | null;
  isActive?: boolean;
  isNavLink?: boolean;
  onClick?: (...args: any[]) => void;
  target?: string;
  url?: string | null;
} & CUILinkProps;

const Link: React.FC<LinkProps> = React.forwardRef<any, LinkProps>(
  ({ asExternal, children, href, isActive = false, isNavLink = false, onClick, target = '_blank', url, ...rest }: LinkProps, ref) => {
    const combinedUrl = url ?? href;

    if (!combinedUrl) {
      return null;
    }

    const handleClick = (event: SyntheticEvent<HTMLAnchorElement> | SyntheticEvent<HTMLButtonElement>) => {
      if (!!onClick) {
        onClick(event);
      }
    };

    const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

    const renderType = getRenderType({ asExternal, isNavLink, url: combinedUrl });

    switch (renderType) {
      case RenderType.NavLinkInternal:
        return (
          <CUILink
            as="button"
            color={isActive ? 'highlight' : undefined}
            fontSize="sm"
            fontWeight={isActive ? 'semibold' : 'medium'}
            onClick={handleClick}
            onKeyPress={handleClick}
            px={3}
            py={2}
            ref={ref}
            rounded="md"
            type="button"
            whiteSpace="nowrap"
            _hover={{ color: 'shipRed.500', bg: 'shipRed.50' }}
            {...rest}
          >
            {children}
          </CUILink>
        );

      case RenderType.NavLinkHash:
        return (
          <CUILink
            color={isActive ? 'highlight' : undefined}
            d="inline-block"
            fontSize="sm"
            fontWeight={isActive ? 'semibold' : 'medium'}
            href={combinedUrl ?? undefined}
            onClick={handleClick}
            px={3}
            py={2}
            rounded="md"
            whiteSpace="nowrap"
            _hover={{ color: 'shipRed.500', bg: 'shipRed.50' }}
            {...rest}
          >
            {children}
          </CUILink>
        );

      case RenderType.NavLinkExternal:
        return (
          <CUILink
            color={isActive ? 'highlight' : undefined}
            d="inline-block"
            fontSize="sm"
            fontWeight={isActive ? 'semibold' : 'medium'}
            href={combinedUrl ?? undefined}
            onClick={handleClick}
            px={3}
            py={2}
            ref={ref}
            rel={rel}
            rounded="md"
            target={target}
            whiteSpace="nowrap"
            _hover={{ color: 'shipRed.500', bg: 'shipRed.50' }}
            {...rest}
          >
            {children}
          </CUILink>
        );

      case RenderType.LinkInternal:
        return (
          <CUILink as="button" d="inline" onClick={handleClick} onKeyPress={handleClick} ref={ref} type="button" {...rest}>
            {children}
          </CUILink>
        );

      case RenderType.LinkHash:
        return (
          <CUILink d="inline" href={combinedUrl ?? undefined} onClick={handleClick} {...rest}>
            {children}
          </CUILink>
        );

      case RenderType.LinkExternal:
        return (
          <CUILink d="inline" href={combinedUrl ?? undefined} onClick={handleClick} ref={ref} rel={rel} target={target} {...rest}>
            {children}
          </CUILink>
        );
    }
  },
);

Link.displayName = 'Link';

export default Link;
