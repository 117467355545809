import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/core';

import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';

// Types
import { FocusEvent, FormEvent } from 'react';

type Props = {
  id: string;
  isRequired?: boolean;
  isValid: boolean;
  helperText?: string;
  label?: string | null;
  name: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  placeholder: string;
  type: string;
  value?: string | number;
  labelColor?: string;
};

const FormInput = (props: Props) => {
  const { t } = useTranslation('translation');
  return (
    <FormControl isRequired={props.isRequired} mb={4}>
      <FormLabel color={props.labelColor} htmlFor={props.id}>
        {props.label}
      </FormLabel>
      <Input
        id={props.id}
        isRequired={props.isRequired}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
      />
      {isNotNullOrUndefined(props.helperText) && <FormHelperText>{props.helperText}</FormHelperText>}
      {!props.isValid && (
        <FormHelperText color="red.500">
          {props.label} {t('deidentOptions.additional.inputHint')}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormInput;
