import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/core';
import Card from '../../../../components/Card';
import { useFormikContext } from 'formik';

import FormInput from '../../../../components/FormInput';
import FormTextArea from '../../../../components/FormTextArea';
import FormInputPrefix from '../../../../components/FormInputPrefix';

import { FormData } from '../../../../types/types';

type Props = {
  hasPrefix: boolean;
  isValid: boolean;
};

const InputFormDeidentified = ({ hasPrefix, isValid }: Props) => {
  const { handleBlur, handleChange, values } = useFormikContext<FormData>();
  const { t } = useTranslation('translation');

  return (
    <Card cardTitle={t('deidentOptions.additional.title')}>
      <Box as="section">
        <Flex w="100%">
          {hasPrefix && (
            <Box maxW="40%" pr={1}>
              <FormInputPrefix
                id="prefix"
                isValid={isValid}
                label={t('deidentOptions.additional.prefix')}
                isRequired={true}
                labelColor="formLabel"
                name="prefix"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('deidentOptions.additional.prefix')}
                type="text"
                value={values.prefix ?? ''}
              />
            </Box>
          )}

          <Box w="100%">
            <FormInput
              id="patientID"
              isValid={isValid}
              isRequired={true}
              label={t('deidentOptions.additional.patientId')}
              labelColor="formLabel"
              name="patientID"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('deidentOptions.additional.patientId')}
              type="text"
              value={values.patientID ?? ''}
            />
          </Box>
        </Flex>

        <FormTextArea
          id="kommentar"
          isRequired={true}
          isValid={isValid}
          label={t('deidentOptions.additional.comment')}
          labelColor="formLabel"
          name="kommentar"
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={t('deidentOptions.additional.comment')}
          type="text"
          value={values.kommentar}
        />
      </Box>
    </Card>
  );
};

export default InputFormDeidentified;
