import React from 'react';

import AppContainer from '../../container/AppContainer';
import NotAllowed from '../../components/NotAllowed';
import routes from '../../config/routing/routes';

const NotAllowedPage = () => {
  return (
    <AppContainer pageTitle={routes.notAllowed.title}>
      <NotAllowed />
    </AppContainer>
  );
};

export default NotAllowedPage;
