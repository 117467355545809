import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';

export const statusCheck = (status?: number) => {
  if (isNotNullOrUndefined(status) && status === 200) {
    return true;
  }
  return false;
};

export const responseMsgCheck = (xhrResponse: XMLHttpRequest) => {
  if (isNotNullOrUndefined(xhrResponse)) {
    const responseObj = JSON.parse(xhrResponse.response);
    return responseObj.Status;
  }
};
