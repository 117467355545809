import React from 'react';
import ShouldDeidentCheckbox from '../../../components/ShouldDeidentCheckbox';

// Types
type Props = {
  shouldDeidentify: boolean;
  handleDeidentOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const OptionsInputForm = ({ shouldDeidentify, handleDeidentOptionChange }: Props) => {
  return <ShouldDeidentCheckbox shouldDeidentify={shouldDeidentify} handleChange={handleDeidentOptionChange} />;
};

export default OptionsInputForm;
