import React from 'react';

import colors from '../colors';

const landingPage = {
  path: (
    <>
      <path d="M504 261H1v71l251 70 252-70v-71z" fill={colors.shipBlue[500]} />

      <path d="M392 133h112v141H392zM1 133h111v141H1z" fill={colors.shipBlue[500]} />

      <path d="M132 0h241v241H132V0z" fill={colors.shipRed[500]} />

      <path
        d="M345 59V49a10 10 0 00-10-11H231V28h-31v10h-10v21h10v93h-10v-21h-21v21h-10v21h10v41h11l10-1v-40h52v37c6 2 13 3 20 3v-40h11v-21h-11v-21h-20v21h-11V59h83v48a13 13 0 00-2 22v13h6c4 0 7 3 7 6 0 4-3 6-7 6-2 0-4-1-5-3a6 6 0 00-9-2c-3 2-4 6-2 9 3 5 10 9 16 9a19 19 0 007-37v-1c4-2 6-6 6-10 0-5-3-9-7-11V59h21zm-124 79l-10 10v-14l10-11v15zm0-30l-10 10v-15l10-10v15zm-10-21V72l10-10v15l-10 10z"
        fill={colors.white}
      />
    </>
  ),
  viewBox: '0 0 504 403',
};

export default landingPage;
