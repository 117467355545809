import { describe, Type } from '@mrboolean/envconfig';

type EnvConfig = {
  appName: string;
  baseUrl: string;
  isDev: boolean;
  isProduction: boolean;
};

const envConfig: EnvConfig = describe({
  appName: {
    name: 'REACT_APP_DICOM_UPLOADER_APP_NAME',
    type: Type.STRING,
    isRequired: false,
    standard: 'DicomUploader',
  },
  baseUrl: {
    name: 'REACT_APP_DICOM_UPLOADER_BASE_URL',
    type: Type.STRING,
    isRequired: false,
    standard: '/',
  },
  isDev: {
    name: 'NODE_ENV',
    isRequired: true,
    sanitize: (value: any): boolean => value !== 'production',
  },
  isProduction: {
    name: 'NODE_ENV',
    isRequired: true,
    sanitize: (value: any): boolean => value === 'production',
  },
});

export default envConfig;
