import React from 'react';
import Card from '../../Card';

import CardLoading from '../CardLoading';

// Types
import { CardProps } from '../Card';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  suspenseCardTitle?: string;
} & CardProps;

const SSCard = ({ children, suspenseCardTitle, ...rest }: Props) => {
  return (
    <React.Suspense fallback={<CardLoading cardTitle={suspenseCardTitle} {...rest} />}>
      <Card /* maxW="60rem" */ mt={4} mx="auto" w={['100%', '100%' /* '90%', '75%' */]} {...rest}>
        {children}
      </Card>
    </React.Suspense>
  );
};

export default SSCard;
