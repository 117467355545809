import React, { ReactNode } from 'react';
import { Flex } from '@chakra-ui/core';

type Props = {
  children: ReactNode;
};

const UploadFlex = ({ children }: Props) => {
  return (
    <Flex
      align="center"
      borderColor="gray.300"
      borderStyle="dashed"
      borderWidth="3px"
      justify="center"
      w="100%"
      maxHeight="200px"
      overflowY="scroll"
    >
      {children}
    </Flex>
  );
};

export default UploadFlex;
