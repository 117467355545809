import React from 'react';

import { Collapse, Divider, List } from '@chakra-ui/core';
// import { Button, Collapse, Divider, List, ListItem, Text } from '@chakra-ui/core';

// import ThemeSwitcher from '../../ThemeSwitcher';
// import useAppContext from '../../../context/AppContext';

// Types
import { ReactNode } from 'react';
import { SVGBoxProps } from '../../SVGBox/SVGBox';

export type NavigationMobileMenuProps = {
  children: ReactNode;
  isMenuOpen: boolean;
  userDisplayName?: string | null;
} & SVGBoxProps;

const NavigationMobileMenu: React.FC<NavigationMobileMenuProps> = React.forwardRef<any, NavigationMobileMenuProps>(
  ({ children, isMenuOpen }: NavigationMobileMenuProps, ref) => {
    // const { isDarkModeDisabled } = useAppContext();

    return (
      <Collapse d={['flex', 'none']} flexDirection="column" isOpen={isMenuOpen} mt={4} ref={ref} w="100%">
        <List d="flex" flexDirection="column" ml="-0.75rem" spacing={2} w={['full', 'auto']}>
          {children}
        </List>

        <>
          <Divider my={3} />

          <List d="flex" flexDirection="column" spacing={2} w={['full', 'auto']}>
            {/* {!isDarkModeDisabled && (
                <ListItem>
                  <ThemeSwitcher />
                </ListItem>
              )} */}

            {/* <ListItem>
              <Text fontSize="sm" fontWeight="semibold">
                {userDisplayName}
              </Text>
            </ListItem>

            <ListItem>
              <Button fontSize="sm" fontWeight="semibold" variant="link">
                Ausloggen
              </Button>
            </ListItem> */}
          </List>
        </>
      </Collapse>
    );
  },
);

NavigationMobileMenu.displayName = 'NavigationMobileMenu';

export default NavigationMobileMenu;
