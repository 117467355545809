import React from 'react';

import colors from '../colors';

const coronaDashboard = {
  path: (
    <>
      <path d="M243.9 127.8H11.5v32.6L127.7 193l116.2-32.6v-32.6z" fill={colors.shipBlue[500]} />

      <path fill={colors.shipBlue[500]} d="M192.5 68.5h51.4v65.1h-51.4zM11.5 68.5h51.4v65.1H11.5z" />

      <path d="M71.9 9.5h111.6v110.4H71.9V9.5z" fill={colors.shipRed[500]} />

      <path
        d="M154.9 64.7c0 5.4-1.6 10.6-4.6 15.1-3 4.5-7.2 8-12.2 10-5 2.1-10.4 2.6-15.7 1.5-5.3-1-10.1-3.6-13.9-7.4-3.8-3.8-6.4-8.7-7.4-13.9-1-5.3-.5-10.7 1.5-15.7 2.1-5 5.5-9.2 10-12.2s9.7-4.6 15.1-4.6v27.2h27.2z"
        fill={colors.white}
      />

      <path
        d="M154.9 64.7c0 5.4-1.6 10.6-4.6 15.1s-7.2 8-12.2 10c-5 2.1-10.4 2.6-15.7 1.5-5.3-1.1-10.1-3.6-13.9-7.4-3.8-3.8-6.4-8.7-7.4-13.9-1-5.3-.5-10.7 1.5-15.7 2.1-5 5.5-9.2 10-12.2s9.7-4.6 15.1-4.6v27.2h27.2z"
        fill={colors.white}
      />

      <path
        d="M132.7 32.5c3.6 0 7.1.7 10.4 2.1 3.3 1.4 6.3 3.4 8.8 5.9 2.5 2.5 4.5 5.5 5.9 8.8 1.4 3.3 2.1 6.8 2.1 10.4h-27.2V32.5zM151.7 87.5c-5.7-1-7.9-6.7-8.2-9.4l-6.2 5.3c2.7 0 6.9 5.1 8.8 10.6 2.3 6.9 6.3 2.4 7.6.8 1.3-1.5 5.1-6.1-2-7.3zM127.6 96.4c-2.4-5.3 1-10.3 3-12.1l-7.9-2.1c1.6 2.2-.3 8.6-3.8 13.2-4.4 5.8 1.6 6.5 3.7 6.8 2 .2 8 .8 5-5.8zM103.5 87.3c2.2-5.4 8.2-6.2 10.9-6l-3.8-7.2c-.6 2.7-6.6 7.5-12.4 8.1-7.2.7-3.7 5.6-2.5 7.3 1.3 1.6 5 4.5 7.8-2.2zM94.5 64.6c5.1-2.8 10.3.2 12.3 2.1l1.5-8c-2.1 1.7-8.6.4-13.5-2.7-6.1-3.9-6.4 2.1-6.5 4.2 0 1.9-.1 7.9 6.2 4.4zM103.4 37.5c5.3 2.4 6 8.4 5.6 11.1l7.3-3.6c-2.6-.6-5.4-6.7-5.8-12.5-.5-7.2-5.5-3.9-7.2-2.7-1.7 1.2-6.5 4.7.1 7.7z"
        fill={colors.white}
      />
    </>
  ),
  viewBox: '0 0 255 203',
};

export default coronaDashboard;
