import React from 'react';
import { Helmet } from 'react-helmet-async';

import App from '../../components/App';
import CardLoading from '../../components/Card/CardLoading';
import ErrorBoundary from '../ErrorBoundary';
import NavigationContainer from '../Navigation';

// Types
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  pageTitle?: string;
};

const AppContainer = ({ children, pageTitle }: Props) => {
  return (
    <>
      {!!pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}

      <NavigationContainer />

      <ErrorBoundary>
        <React.Suspense
          fallback={
            <App>
              <CardLoading />
            </App>
          }
        >
          <App>{children}</App>
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};
export default AppContainer;
