import React from 'react';
import { Flex } from '@chakra-ui/core';

import Card from '../../components/Card/SSCard';
import OptionsForm from '../Form/DeidentOptions';

import UserInputForm from '../Form/UserInputForm';

// Types
import { FormData, DeidentUserInputOptions } from '../../types/types';
import ShouldDeidentifyChoice from '../Form/ShouldDeidentifyChoice';

type Props = {
  deidentUserInputOptions: DeidentUserInputOptions;
  isValid: boolean;
  setDeidentUserInputOptions: React.Dispatch<React.SetStateAction<DeidentUserInputOptions>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  setUserFormInput: React.Dispatch<React.SetStateAction<FormData>>;
  shouldDeidentify: boolean;
  handleDeidentOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function UploadUserInputFormContainer({
  deidentUserInputOptions,
  isValid,
  setDeidentUserInputOptions,
  setIsValid,
  setUserFormInput,
  shouldDeidentify,
  handleDeidentOptionChange,
}: Props) {
  return (
    <Flex align="center" mx={2} minWidth="40vw" alignItems="stretch" flexWrap="wrap">
      <Card mt={0}>
        <ShouldDeidentifyChoice shouldDeidentify={shouldDeidentify} handleDeidentOptionChange={handleDeidentOptionChange} />
      </Card>
      <Card mt={0} display="block">
        <UserInputForm setUserFormInput={setUserFormInput} isValid={isValid} setIsValid={setIsValid} shouldDeidentify={shouldDeidentify} />
      </Card>
      <Card mt={0}>
        <OptionsForm
          deidentUserInputOptions={deidentUserInputOptions}
          setDeidentUserInputOptions={setDeidentUserInputOptions}
          shouldDeidentify={shouldDeidentify}
        />
      </Card>
    </Flex>
  );
}

export default UploadUserInputFormContainer;
