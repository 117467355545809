import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@chakra-ui/core';

import Card from '../../components/Card';
import Headline from '../../components/Headline';
import { initializedOptionValues, initializedNormalFormInputValues } from './initializedValues';
import UploadFlexContainer from '../UploadFlexContainer';
import Error from '../../components/Error';

// Types
import { FormData, DeidentUserInputOptions, Params } from '../../types/types';
import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';

type Props = {};
interface RefObject {
  fileCheck: () => void;
}

function UploadContainer(_props: Props) {
  const params: Params = useParams();
  const [shouldDeidentify, setShouldDeidentify] = React.useState<boolean>(!!isNotNullOrUndefined(params.prefix));
  const [userFormInput, setUserFormInput] = React.useState<FormData>(initializedNormalFormInputValues);
  const [deidentUserInputOptions, setDeidentUserInputOptions] = React.useState<DeidentUserInputOptions>(initializedOptionValues);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const uploadComponentRef = React.useRef<RefObject>();

  const handleDeidentOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setShouldDeidentify(checked);
    uploadComponentRef?.current?.fileCheck();
  };

  useEffect(() => {
    if (shouldDeidentify) {
      setIsValid(false);
    }
    if (!shouldDeidentify) {
      setIsValid(true);
    }
  }, [shouldDeidentify]);

  // Check browser support
  if (window.FileReader && window.Blob) {
    return (
      <Card p={0}>
        <Stack bg="gray.300" boxShadow="md" rounded="lg" align="center">
          <Headline />
          <React.Suspense fallback={() => <Error />}>
            <UploadFlexContainer
              ref={uploadComponentRef}
              handleDeidentOptionChange={handleDeidentOptionChange}
              deidentUserInputOptions={deidentUserInputOptions}
              isValid={isValid}
              setDeidentUserInputOptions={setDeidentUserInputOptions}
              setIsValid={setIsValid}
              setShouldDeidentify={setShouldDeidentify}
              setUserFormInput={setUserFormInput}
              shouldDeidentify={shouldDeidentify}
              userFormInput={userFormInput}
            />
          </React.Suspense>
        </Stack>
      </Card>
    );
  }

  // No browser support for file and blob
  return <h1>Der Browser wird nicht unterstützt</h1>;
}

export default UploadContainer;
