import React from 'react';
import { Stack } from '@chakra-ui/core';

import isNotNullOrUndefined from '../../utils/isNotNullOrUndefined';
import UploadError from '../UploadError';
import UploadServerError from '../../components/UploadServerError';
import UploadSuccess from '../UploadSuccess';
import RejectedFilesContainer from '../RejectedFilesContainer';

import { ErrorInfo, NotAcceptedFile } from '../../types/types';

type Status = {
  uploadErrors?: string | ErrorInfo[];
  uploadErrorText?: string;
  uploadIsDone: boolean;
  rejectedFiles: NotAcceptedFile[];
};

function UploadStatusContainer({ uploadErrors, uploadErrorText, uploadIsDone, rejectedFiles }: Status) {
  const rejectedSCFiles = rejectedFiles.map(errorEntry => {
    if (errorEntry.reason === 'Secondary Captures Image Storages') {
      return errorEntry.file.path;
    }
    return null;
  });
  const rejectedSCFilesAsAString = rejectedSCFiles.join('\r\n');
  return (
    <Stack mt={2}>
      {uploadIsDone && Array.isArray(uploadErrors) && uploadErrors.length === 0 && !isNotNullOrUndefined(uploadErrorText) && (
        <UploadSuccess />
      )}
      {/* If status is a string, there was an promise rejection */}
      {isNotNullOrUndefined(uploadErrorText) && <UploadServerError uploadErrorText={uploadErrorText} />}
      {Array.isArray(uploadErrors) && uploadErrors.length > 0 && <UploadError uploadErrors={uploadErrors} />}
      {uploadIsDone && rejectedSCFiles.length !== 0 && <RejectedFilesContainer rejectedSCFilesAsAString={rejectedSCFilesAsAString} />}
    </Stack>
  );
}

export default UploadStatusContainer;
