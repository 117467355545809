import React from 'react';
import { Button as CUButton } from '@chakra-ui/core';

// Types
import { ButtonProps as CUButtonProps } from '@chakra-ui/core';

export type ButtonProps = {} & CUButtonProps;

const Button: React.FC<ButtonProps> = React.forwardRef<any, ButtonProps>(({ ...rest }: ButtonProps, ref) => {
  return <CUButton ref={ref} variantColor="shipRed" {...rest} />;
});

Button.displayName = 'Button';

export default Button;
