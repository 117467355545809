import React from 'react';
import { Box, Heading, Link, Stack, Tag, Text, Icon } from '@chakra-ui/core';
import Card from '../Card';
import { useTranslation } from 'react-i18next';

// Types

type Props = {};

const About = (_props: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Card minHeight="80vh">
      <Stack as={Box} textAlign="center" spacing={{ base: 8, md: 14 }} py={3}>
        <Heading fontWeight={600} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight="110%">
          About
        </Heading>
        <Heading fontWeight={600} fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }} lineHeight="110%" py={4}>
          <Text as="span" color="shipRed.600">
            Dicom-deidentifier
          </Text>
        </Heading>
        <Text color="gray.700">{t('aboutPage.text')}</Text>
        <Stack direction="column" spacing={3} align="center" alignSelf="center" position="relative">
          <Link href="https://github.com/UMEssen/dicom-deidentifier-ts" isExternal={true} color="shipRed.500">
            https://github.com/UMEssen/dicom-deidentifier-ts <Icon name="external-link" size="1rem" />
          </Link>
        </Stack>
        <Stack direction="column" spacing={3} align="center" alignSelf="center" position="relative">
          <Tag size="sm">Dicom Uploader v1.3.9</Tag>
        </Stack>
      </Stack>
    </Card>
  );
};
export default About;
