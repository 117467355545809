import React from 'react';

import AppContainer from '../../container/AppContainer';
import NotFound from '../../components/NotFound';
import routes from '../../config/routing/routes';

const NotFoundPage = () => {
  return (
    <AppContainer pageTitle={routes.notAllowed.title}>
      <NotFound />
    </AppContainer>
  );
};

export default NotFoundPage;
