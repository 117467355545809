import React from 'react';
import { Route, Switch } from 'react-router';

import About from '../../pages/About';
import ErrorPage from '../../pages/Error';
import NotAllowedPage from '../../pages/NotAllowed';
import NotFoundPage from '../../pages/NotFound';
import Upload from '../../pages/Upload';

import routes from './routes';

const RoutesProvider = () => {
  return (
    <Switch>
      <Route exact={true} path={routes.error.path} component={ErrorPage} />

      <Route exact={true} path={routes.upload.path} render={() => <Upload key={window.location.pathname} />} />

      <Route exact={true} path={routes.uploadWithPrefix.path} render={() => <Upload key={window.location.pathname} />} />

      <Route exact={true} path={routes.about.path} render={() => <About />} />

      <Route exact={true} path={routes.notAllowed.path} component={NotAllowedPage} />

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default RoutesProvider;
