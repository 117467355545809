import React from 'react';

import colors from '../colors';

const terminologist = {
  path: (
    <>
      <path d="M504 261H1v71l251 70 252-70v-71z" fill={colors.shipBlue[500]} />

      <path d="M392 133h112v141H392zM1 133h111v141H1z" fill={colors.shipBlue[500]} />

      <path d="M132 0h241v242H132V0z" fill={colors.shipRed[500]} />

      <path
        fill={colors.white}
        d="M288 71H168c-3 0-5 2-5 4v21c0 2 2 4 5 4h120v17c0 8 10 12 16 6l34-31c4-4 4-9 0-13l-34-31c-6-6-16-2-16 6v17zM216 146h120c3 0 5 2 5 4v21c0 2-2 4-5 4H216v17c0 8-10 12-16 6l-34-31c-4-4-4-9 0-13l34-31c6-6 16-2 16 6v17z"
      />
    </>
  ),
  viewBox: '0 0 504 403',
};

export default terminologist;
