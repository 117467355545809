import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/core';

import './flagIcon.css';

type Props = {};

const LanguageToggle = (_props: Props) => {
  const { i18n } = useTranslation();

  const changeLanguageOnClick = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Menu>
        <MenuButton aria-label="Select Language" className={i18n.resolvedLanguage} />

        <MenuList p={0} minW="0" w="auto">
          <MenuItem className="de" width="40px" height="40px" marginBottom={2} onClick={() => changeLanguageOnClick('de')} />
          <MenuItem className="en" width="40px" height="40px" onClick={() => changeLanguageOnClick('en')} />
        </MenuList>
      </Menu>
    </>
  );
};

export default LanguageToggle;
