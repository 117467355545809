import React from 'react';
import { Stack } from '@chakra-ui/core';

import ResetUpload from '../../container/ResetUpload';
import UploadSuccessMsg from '../../components/UploadSuccessMsg';

type Props = {};

const UploadSuccess = (_props: Props) => {
  return (
    <>
      <UploadSuccessMsg />
      <Stack my={2}>
        <ResetUpload />
      </Stack>
    </>
  );
};

export default UploadSuccess;
