import React from 'react';
import { Stack, AlertIcon, Alert } from '@chakra-ui/core';

import ResetUpload from '../../container/ResetUpload';
import UploadErrorComponent from '../../components/UploadError';

import { ErrorInfo } from '../../types/types';

type Props = {
  uploadErrors?: ErrorInfo[];
};

const UploadError = ({ uploadErrors }: Props) => {
  return (
    <Stack spacing={3}>
      <Alert status="error">
        <AlertIcon />
        Es sind Fehler aufgetreten:
      </Alert>
      {uploadErrors?.map((err: ErrorInfo, idx: number) => (
        <UploadErrorComponent error={err} key={`ulerror${idx.toString()}`} />
      ))}
      <Stack my={2}>
        <ResetUpload />
      </Stack>
    </Stack>
  );
};

export default UploadError;
