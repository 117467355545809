import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Heading } from '@chakra-ui/core';

type Props = {};

const Headline = (_props: Props) => {
  const { t } = useTranslation('translation');
  return (
    <Stack spacing={3} align="center" py={2}>
      <Heading as="h2" size="xl" color="black.500">
        {t('app.header')}
      </Heading>
    </Stack>
  );
};

export default Headline;
